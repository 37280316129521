import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { FIREBASE_KEYS } from "constants/appConstants";
import { IUser } from "model/user-model";
import { ROUTE_CONSTANTS } from "constants/routeConstants";

export class AuthStore {
  private rootStore;
  private tempUser: any = null;
  user: IUser | null = null;
  loginError: string = "";

  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get isAuthenticated() {
    return !!this.user;
  }

  getUserData = (user: any): Promise<any> =>
    new Promise((resolve) => {
      const db = getFirestore();
      const userRef = doc(db, FIREBASE_KEYS.USERS, user.uid);
      getDoc(userRef).then((userSnap) => {
        if (userSnap) {
          resolve({
            userFound: true,
            data: userSnap.data(),
          });
        } else {
          resolve({
            userFound: false,
            data: null,
          });
        }
      });
    });

  authenticate = async (formData: any, navigate: any, reset: any) => {
    this.rootStore.loadingStore.setIsLoading("button", true);
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      // Signed in
      const { user } = userCredential;

      if (!user.emailVerified) {
        this.tempUser = user;
        navigate(ROUTE_CONSTANTS.EMAIL_VERIFY, { replace: true });
        this.rootStore.loadingStore.setIsLoading("button", false);
        reset();

        return;
      }
      const { userFound, data } = await this.getUserData(user);

      if (userFound && data) {
        this.user = {
          ...data,
          userId: user.uid,
        };
        this.rootStore.loadingStore.setIsLoading("button", false);

        navigate(ROUTE_CONSTANTS.DASHBOARD);
      } else {
        throw new Error("Utilizator negasit - contact admin.");
      }
    } catch (err: any) {
      if (err.message.indexOf("wrong-password")) {
        this.loginError = "Utilizator sau parola gresite.";
      } else {
        this.loginError = "Ceva nu a mers bine, contacteaza suport.";
      }

      setTimeout(() => {
        this.loginError = "";
      }, 4000);
      this.rootStore.loadingStore.setIsLoading("button", false);
    }
  };

  sendEmail = () => {
    if (!this?.tempUser) {
      return;
    }

    sendEmailVerification(this.tempUser).then(() => {
      this.rootStore.loadingStore.toastRef.current.show({
        severity: "success",
        summary: "Email Trimis",
        detail: "Un email de confirmare a fost trimis cu success!",
        life: 3000,
      });
    });
  };

  createUser = async (formData: any, navigate: any, reset: any) => {
    this.rootStore.loadingStore.setIsLoading("button", true);
    const auth = getAuth();

    const writeUserData = async (
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      address: string,
      policy: boolean
    ) => {
      const db = getFirestore();
      const newUser = doc(db, FIREBASE_KEYS.USERS, userId);

      try {
        await setDoc(newUser, {
          firstName,
          lastName,
          email,
          address,
          policy,
        });
      } catch (err: any) {
        this.loginError = err.message;
        this.rootStore.loadingStore.setIsLoading("button", false);

        console.log(err);
      }
    };

    createUserWithEmailAndPassword(auth, formData.email, formData.password)
      .then((response) => {
        const { user } = response;
        this.tempUser = user;
        writeUserData(
          user.uid,
          formData.name,
          formData.lastName,
          formData.email,
          formData.address,
          true
        );
        this.sendEmail();
        this.rootStore.loadingStore.setIsLoading("button", false);

        navigate(ROUTE_CONSTANTS.CONFIRMATION, { replace: true });
      })
      .catch((err) => {
        this.loginError = "Utilizatorul exista deja sau ceva nu a mers bine!";
        this.rootStore.loadingStore.setIsLoading("button", false);
        setTimeout(() => {
          this.loginError = "";
        }, 4000);
      });
  };

  sendVerificationEmail = () => {
    if (!this.tempUser) return;
    this.sendEmail();
  };

  sendResetPasswordEmail = async (formData: any, navigate: any) => {
    this.rootStore.loadingStore.setIsLoading("button", true);

    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, formData.email);
      this.rootStore.loadingStore.toastRef.current.show({
        severity: "success",
        summary: "Email Trimis",
        detail: "Un email de resetare parola a fost trimis cu success!",
        life: 3000,
      });
      this.rootStore.loadingStore.setIsLoading("button", false);
      navigate(ROUTE_CONSTANTS.LOGIN, { replace: true });
    } catch (err) {
      this.rootStore.loadingStore.setIsLoading("button", false);
    }
  };

  logInWithGoogle = async (reset: any, navigate: any) => {
    this.rootStore.loadingStore.setIsLoading("googleButton", true);
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const db = getFirestore();

      if (!result.user) {
        this.rootStore.loadingStore.setIsLoading("googleButton", false);

        return;
      }

      const { userFound, data } = await this.getUserData(result.user);

      if (userFound && data) {
        this.user = {
          ...data,
          userId: result.user.uid,
        };
        this.rootStore.loadingStore.setIsLoading("googleButton", false);

        navigate(ROUTE_CONSTANTS.DASHBOARD, { replace: true });

        return;
      }

      const newUser = doc(db, FIREBASE_KEYS.USERS, result.user.uid);
      setDoc(newUser, {
        firstName: result.user.displayName,
        lastName: "",
        email: result.user.email,
        address: "",
        policy: true,
      })
        .then(() => {
          // @ts-ignore
          this.getUserData(result.user).then((response) => {
            const { userFound, data } = response;
            if (userFound && data) {
              this.user = {
                ...data,
                userId: result.user.uid,
              };
              this.rootStore.loadingStore.setIsLoading("googleButton", false);

              navigate(ROUTE_CONSTANTS.DASHBOARD, { replace: true });
            }
          });
        })
        .catch((err: any) => {
          this.loginError = err;
        });
    } catch (error: any) {
      console.log(error);
    }
  };

  logOut = (navigate: any) => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.user = null;
        navigate(ROUTE_CONSTANTS.AUCTIONS);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkUserStatus = (navigate: any) => {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.getUserData(user).then((response) => {
          const { userFound, data } = response;
          if (userFound) {
            this.user = {
              ...data,
              userId: user.uid,
            };
            navigate(ROUTE_CONSTANTS.DASHBOARD);
          }
        });
      }
    });
  };
}
