interface Props {
  leftChildren?: any;
  rightChildren?: any;
}

export const Header = ({ leftChildren, rightChildren }: Props) => {
  return (
    <div className="flex flex-column w-full justify-space-between align-items-end mb-2 ">
      <div>{leftChildren}</div>
      <div>{rightChildren}</div>
    </div>
  );
};
