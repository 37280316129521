import { ROUTE_CONSTANTS } from "constants/routeConstants";
import { Navigate, useLocation } from "react-router-dom";
import { useStore } from "store";

export const ProtectedRoute = ({ children }: { children: any }) => {
  const { authStore } = useStore();
  const { isAuthenticated } = authStore;
  const location = useLocation();

  return isAuthenticated === true ? (
    children
  ) : (
    <Navigate
      to={ROUTE_CONSTANTS.LOGIN}
      replace
      state={{ path: location.pathname }}
    />
  );
};
