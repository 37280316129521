import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "components/button/button";
import { Divider } from "primereact/divider";
import { Link, useNavigate } from "react-router-dom";
import icon from "../../assets/png/icon.png";
import styles from "./login.module.scss";
import { classNames } from "primereact/utils";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { Message } from "primereact/message";
import { useState } from "react";
import { ROUTE_CONSTANTS } from "constants/routeConstants";

const defaultValues = {
  email: "",
  password: "",
};

export const Login = observer(() => {
  const { authStore, loadingStore } = useStore();
  const { isLoading } = loadingStore;
  const { loginError } = authStore;
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data: any) => {
    authStore.authenticate(data, navigate, reset);
  };

  const getFormErrorMessage = (name: "email" | "password") => {
    return (
      errors[name] && <small className="p-error">{errors[name]?.message}</small>
    );
  };

  const loginWithGoogle = () => {
    authStore.logInWithGoogle(reset, navigate);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-1 bg-cyan-50 m-auto w-full h-full max-h-full overflow-auto p-5 ${styles.container}`}
    >
      <div className="card bg-white w-20rem shadow-4 border-round-lg flex justify-content-center align-items-center m-auto flex-column">
        <div className="w-5rem mt-3">
          <img
            src={icon}
            alt="an pigeon with an dog and a cat with a gradient blue to red background"
            className="w-auto h-full max-h-full max-w-full"
          />
        </div>
        <h2 className="text-blue-800">Bine ai revenit!</h2>
        <div className="m-2">
          <span className="p-input-icon-left  ">
            <i className="pi pi-at" />
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email-ul este obligatoriu!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "E-mail invalid!",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  placeholder="Email"
                  className={classNames("p-inputtext-sm w-15rem shadow-2", {
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("email")}</div>
        </div>
        <div className="m-2">
          <span className="p-input-icon-left p-input-icon-right m-2">
            <i className="pi pi-key" />
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Completeaza o parola",
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  placeholder="Parola"
                  type={showPassword ? "text" : "password"}
                  {...field}
                  className={classNames("p-inputtext-sm w-15rem shadow-2", {
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <i
              className={`pi ${
                showPassword ? "pi-eye-slash" : "pi-eye"
              } cursor-pointer`}
              onClick={() => setShowPassword(!showPassword)}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("password")}</div>
        </div>
        <div className="flex m-2 justify-content-between w-15rem">
          <Button
            type="submit"
            loading={isLoading.button}
            label="Login"
            onClick={() => {}}
          />
          <Button
            type="button"
            loading={isLoading.button}
            label="Renunta"
            onClick={() => navigate(ROUTE_CONSTANTS.AUCTIONS)}
            variant="danger"
          />
        </div>
        {loginError && (
          <Message className="w-15rem" severity="error" text={loginError} />
        )}
        <Link
          className=" no-underline text-blue-800 w-15rem text-right m-2 font-bold"
          to="/reset"
        >
          Ai uitat parola?
        </Link>
        <Divider align="center" className="w-15rem font-bold m-1">
          <span className="p-tag">sau</span>
        </Divider>

        <Button
          label=""
          onClick={loginWithGoogle}
          type="button"
          loading={isLoading.googleButton}
          variant="outlined"
        >
          <i className="pi pi-google px-2"></i>
          <span className="px-3 margin-auto">Login cu Google </span>
        </Button>

        <div className="flex w-15rem justify-content-center b-2">
          <span>Nu ai cont?</span>
          <Link
            className="no-underline text-blue-600 font-bold mb-5"
            to="/signup"
          >
            {" "}
            creaza cont nou!{" "}
          </Link>
        </div>
      </div>
    </form>
  );
});
