import { Button } from "components/button/button";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import "./page-container.scss";

interface IProps {
  name: string;
  hasBackButton?: boolean;
  children: any;
}

export const PageContainer = ({ name, hasBackButton, children }: IProps) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className={`w-full page-detail-content`}>
      <header className="flex justify-content-between h-3rem mb-2 align-items-center">
        <h2 className="text-blue-800 m-0">{name}</h2>
        <div className="w-6 flex justify-content-end h-full align-items-center">
          {hasBackButton && (
            <Button
              label="Inapoi"
              icon="pi pi-arrow-left"
              onClick={goBack}
              variant="outlined"
            />
          )}
        </div>
      </header>
      <Divider className="m-2" />
      <div className="w-full page-detail-content">{children}</div>
    </div>
  );
};
