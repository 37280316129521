interface IProps {
  label: string;
  value: string;
}

export const DetailItem = ({ label, value }: IProps) => {
  return (
    <p className="m-0 ">
      <p className="m-0 text-gray-400 font-bold">{label}:</p>
      <p className="m-0 mb-2 p-1 text-blue-700 font-bold border-round-md border-1 border-gray-300">
        {value}
      </p>
    </p>
  );
};
