import React, { useRef } from "react";
import { Toolbar as PrimeToolbar } from "primereact/toolbar";
import styles from "./toolbar.module.scss";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routeConstants";

interface Props {
  setSideMenuOpen: Function;
  sideMenuOpen: boolean;
  defaultOpen: boolean;
}

export const Toolbar = observer(
  ({ setSideMenuOpen, sideMenuOpen, defaultOpen }: Props) => {
    const { authStore } = useStore();
    const { user, isAuthenticated } = authStore;
    const menu: any = useRef(null);
    const navigate = useNavigate();

    const handleSideMenuOpen = () => {
      setSideMenuOpen(!sideMenuOpen);
    };

    const leftContents = (
      <React.Fragment>
        {defaultOpen && (
          <Button
            icon="pi pi-bars"
            aria-label="Submit"
            className={`${styles.toolbarButton} ${
              sideMenuOpen ? styles.toolbarButtonOpen : ""
            } p-button-rounded p-button-outlined p-button-sm bg-white`}
            onClick={handleSideMenuOpen}
          />
        )}
      </React.Fragment>
    );

    const items = [
      ...(isAuthenticated
        ? [
            {
              label: "Detalii",
              items: [
                {
                  label: "Profil",
                  icon: "pi pi-user",
                  command: () => navigate(ROUTE_CONSTANTS.PROFILE),
                },
              ],
            },
            {
              label: "Securitate",
              items: [
                {
                  label: "Logout",
                  icon: "pi pi-sign-out",
                  command: () => authStore.logOut(navigate),
                },
                {
                  label: "Schimba parola",
                  icon: "pi pi-pencil",
                  command: () => {},
                },
              ],
            },
          ]
        : [
            {
              label: "Autentificare",
              items: [
                {
                  label: "Login",
                  icon: "pi pi-shield",
                  command: () => navigate(ROUTE_CONSTANTS.LOGIN),
                },
                {
                  label: "Creaza utilizator",
                  icon: "pi pi-user-plus",
                  command: () => navigate(ROUTE_CONSTANTS.SIGNUP),
                },
              ],
            },
          ]),
    ];

    const rightContents = (
      <React.Fragment>
        <Menu model={items} popup ref={menu} id="popup_menu" />
        <Button
          label={user ? user.firstName : "User"}
          icon="pi pi-user"
          onClick={(event) => menu?.current && menu.current.toggle(event)}
          aria-controls="popup_menu"
          aria-haspopup
          className="p-button-outlined p-button-sm text-blue-700"
          style={{
            borderColor: "blue",
            color: "blue",
          }}
        />
      </React.Fragment>
    );

    return (
      <>
        <PrimeToolbar
          className={`p-2 mb-3 shadow-2 flex align-items-center justify-content-between bg-white text-blue-700`}
          left={leftContents}
          right={rightContents}
        />
      </>
    );
  }
);
