export const ALWAYS_SHOW_MENU_FROM_WIDTH = 1024;

export const FIREBASE_KEYS = {
  USERS: "users",
  PETS: "pets",
  PIGEONS: "pigeons",
  AUCTIONS: "auctions",
};

export const VIEW_TYPE = {
  GRID: "GRID",
  LIST: "LIST",
};
