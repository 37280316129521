import React, { useMemo, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { useForm } from "react-hook-form";
import { FormInput } from "./form-input/form-input";
import { FormSelect } from "./form-select/form-select";
import { CountrySelect } from "./country-select/country-select";
import { DetailInput } from "./detail-input/detail-input";
import { statusConstants } from "constants/statusConstants";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { Button } from "components/button/button";
import { Button as PrimeButton } from "primereact/button";

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  petData?: any;
  status?: string;
  disableStatus?: boolean;
  defaultGender?: string;
  child?: any;
  isAddingParent?: boolean;
}

export type fieldType =
  | "name"
  | "country"
  | "ringNumber"
  | "gender"
  | "year"
  | "family"
  | "boxNumber"
  | "status"
  | "description"
  | "father"
  | "mother"
  | "petColor"
  | "eyeColor";

export const AddEditPet = observer(
  ({
    visible,
    setVisible,
    petData,
    status = "Activ",
    disableStatus,
    defaultGender = "",
    child,
    isAddingParent = false,
  }: IProps) => {
    const { petStore, loadingStore } = useStore();
    const { petsFatherOptions, petsMotherOptions } = petStore;
    const { isLoading } = loadingStore;
    const inputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<any>();

    const defaultValues = useMemo(
      () => ({
        name: "",
        country: "RO",
        ringNumber: "",
        gender: defaultGender,
        father: "",
        mother: "",
        year: "",
        petColor: "",
        eyeColor: "",
        family: "",
        boxNumber: "",
        status: status,
        description: "",
      }),
      [status, defaultGender]
    );

    const {
      control,
      formState: { errors },
      handleSubmit,
      reset,
    } = useForm({ defaultValues });

    const getFormErrorMessage = (name: fieldType) => {
      return (
        errors[name] && (
          <small className="p-error">{errors[name]?.message}</small>
        )
      );
    };

    const closeForm = () => {
      reset();
      setVisible(false);
    };

    const onSubmit = (formData: any) => {
      if (isAddingParent) {
        petStore.createParent({
          pet: child,
          closeForm,
          parent: { ...formData, gender: defaultGender },
        });
        return;
      }
      petStore.createPigeon(formData, closeForm, file);
    };

    const handleUploadClick = () => {
      if (!inputRef?.current) {
        return;
      }

      inputRef?.current?.click();
    };

    const handleChange = (file: any) => {
      setFile(file);
    };

    return (
      <Sidebar
        visible={visible}
        onHide={() => setVisible(false)}
        position="right"
      >
        <h3>{petData ? "Editeaza porumbel" : "Adauga porumbel"}</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-2 bm-3 pr-3">
            <PrimeButton
              type="button"
              className="w-full p-button-text p-button-raised"
              onClick={() => handleUploadClick()}
            >
              <input
                type="file"
                ref={inputRef}
                id="chooseFile"
                accept="*.png, *.jpg, *.jpeg"
                onChange={(e) => handleChange(e.target?.files?.[0] || null)}
                hidden
              />
              {file ? file.name : "Selecteaza imaginea"}
            </PrimeButton>
          </div>
          <FormInput
            field="name"
            label="Nume"
            control={control}
            rules={{
              required: "Numele este obligatoriu!",
            }}
            getFormErrorMessage={getFormErrorMessage}
          />
          <CountrySelect
            field="country"
            label="Tara"
            control={control}
            getErrorMessage={getFormErrorMessage}
            rules={{
              required: "Tara este obligatorie!",
            }}
          />
          {!isAddingParent && (
            <>
              <FormSelect
                field="father"
                label="Tata"
                control={control}
                options={petsFatherOptions}
                getErrorMessage={getFormErrorMessage}
              />
              <FormSelect
                field="mother"
                label="Mama"
                control={control}
                options={petsMotherOptions}
                getErrorMessage={getFormErrorMessage}
              />
            </>
          )}
          <FormInput
            field="ringNumber"
            label="Numar Inel"
            control={control}
            rules={{
              required: "Numarul este obligatoriu!",
            }}
            getFormErrorMessage={getFormErrorMessage}
          />
          {!isAddingParent && (
            <FormSelect
              field="gender"
              label="Gen"
              control={control}
              rules={{
                required: "Genul este obligatoriu!",
              }}
              options={[
                { label: "Mascul", value: "male" },
                { label: "Femela", value: "female" },
              ]}
              getErrorMessage={getFormErrorMessage}
              disabled={!!defaultGender}
            />
          )}
          <FormInput
            field="year"
            label="Anul Nasterii"
            control={control}
            rules={{
              required: "Anul este obligatoriu!",
            }}
            getFormErrorMessage={getFormErrorMessage}
          />
          <FormInput
            field="family"
            label="Familie"
            control={control}
            getFormErrorMessage={getFormErrorMessage}
          />
          <FormInput
            field="boxNumber"
            label="Numar Boxa"
            control={control}
            getFormErrorMessage={getFormErrorMessage}
          />
          <FormInput
            field="petColor"
            label="Culoare Porumbel"
            control={control}
            getFormErrorMessage={getFormErrorMessage}
          />
          <FormInput
            field="eyeColor"
            label="Culoare Ochi"
            control={control}
            getFormErrorMessage={getFormErrorMessage}
          />
          <DetailInput
            field="description"
            label="Detalii"
            control={control}
            getFormErrorMessage={getFormErrorMessage}
          />
          {!isAddingParent && (
            <FormSelect
              field="status"
              label="Status"
              control={control}
              disabled={disableStatus}
              rules={{
                required: "Statusul este obligatoriu!",
              }}
              options={statusConstants.map((status) => ({
                value: status,
                label: status,
              }))}
              getErrorMessage={getFormErrorMessage}
            />
          )}
          <div className="flex w-full justify-content-between">
            <Button
              type="submit"
              label="Salveaza"
              loading={isLoading.button}
              disabled={isLoading.button}
              onClick={() => {}}
            />
            <Button
              type="button"
              label="Renunta"
              variant="danger"
              loading={isLoading.button}
              disabled={isLoading.button}
              onClick={() => setVisible(false)}
            />
          </div>
        </form>
      </Sidebar>
    );
  }
);
