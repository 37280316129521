import { NavElement } from "./sidebar-nav-element";
import { Divider } from "primereact/divider";
import styles from "./sidebar.module.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { ROUTE_CONSTANTS } from "constants/routeConstants";
import logo from "../../assets/png/logo.png";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

interface Props {
  isOpen: boolean;
  defaultOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

export const Sidebar = observer(
  ({ isOpen = false, setIsSidebarOpen, defaultOpen = false }: Props) => {
    const { authStore } = useStore();
    const { isAuthenticated } = authStore;

    return (
      <>
        {defaultOpen && (
          <Button
            icon="pi pi-chevron-left"
            className={classNames(styles.sidebarCloseButton, {
              [styles.sidebarCloseButtonHidden]: !isOpen && defaultOpen,
            })}
            aria-label="Filter"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}
        <div
          className={`${styles.sidebar} shadow-3 ${
            isOpen ? `${styles.sidebarOpen} ` : styles.sidebarClose
          } 
       ${defaultOpen && styles.sidebarDefaultOpen}
        pr-2  
        bg-white 
        flex 
        flex flex-column
        pb-5
        pt-2
        `}
        >
          <div className="flex justify-content-center mb-5 mt-4">
            <img src={logo} alt="Pet pedigree" className="w-12rem" />
          </div>

          {isAuthenticated && (
            <NavElement
              to="dashboard"
              label="Dashboard"
              setIsSidebarOpen={setIsSidebarOpen}
              // icon="pi-th-large"
              icon="pi-home"
            />
          )}
          <Divider>Gestionare</Divider>
          {isAuthenticated && (
            <NavElement
              to={ROUTE_CONSTANTS.PET_LIST}
              label="Lista porumbei"
              setIsSidebarOpen={setIsSidebarOpen}
              icon="pi-list"
            />
          )}
          <NavElement
            to={ROUTE_CONSTANTS.AUCTIONS}
            label="Licitatii"
            setIsSidebarOpen={setIsSidebarOpen}
            icon="pi-tag"
          />
          <NavElement
            to={ROUTE_CONSTANTS.PET_SALES}
            label="Vanzari directe"
            setIsSidebarOpen={setIsSidebarOpen}
            icon="pi-shopping-bag"
          />
          {isAuthenticated && (
            <NavElement
              to="/contests"
              label="Concursuri"
              setIsSidebarOpen={setIsSidebarOpen}
              icon="pi-flag"
            />
          )}
          <NavElement
            to="/distance-calculator"
            label="Calculator distante"
            setIsSidebarOpen={setIsSidebarOpen}
            icon="pi-map"
          />

          <Divider>Utilizator</Divider>
          {!isAuthenticated && (
            <>
              <NavElement
                to={ROUTE_CONSTANTS.LOGIN}
                label="Login"
                setIsSidebarOpen={setIsSidebarOpen}
                icon="pi-shield"
              />
              <NavElement
                to={ROUTE_CONSTANTS.SIGNUP}
                label="Signup"
                setIsSidebarOpen={setIsSidebarOpen}
                icon="pi-th-large"
              />
            </>
          )}

          {isAuthenticated && (
            <>
              <NavElement
                to={ROUTE_CONSTANTS.PROFILE}
                label="Profil"
                setIsSidebarOpen={setIsSidebarOpen}
                icon="pi-user"
              />
              <NavElement
                to="/security"
                label="Login si securitate"
                setIsSidebarOpen={setIsSidebarOpen}
                icon="pi-shield"
              />
              <NavElement
                to="/mesages"
                label="Mesaje"
                setIsSidebarOpen={setIsSidebarOpen}
                icon="pi-comment"
              />
              <NavElement
                to="/notifications"
                label="Notificari"
                setIsSidebarOpen={setIsSidebarOpen}
                icon="pi-bell"
              />
            </>
          )}
        </div>
        {isOpen && defaultOpen && (
          <div
            className={styles.sidebarOverlay}
            onClick={() => setIsSidebarOpen(false)}
          />
        )}
      </>
    );
  }
);
