import { PageContainer } from "components/page-container/page-container";
import { Divider } from "primereact/divider";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "store";
import { Contests } from "./contests/contests";
import { DetailActions } from "./detail-actions/detail-actions";
import { DetailItem } from "./detail-item/detail-item";
import defaultImg from "../../assets/png/pigeon.png";
import { Card } from "components/card/card-component";

export const PetDetail = () => {
  const { petStore } = useStore();
  const params = useParams();

  const pet = useMemo(
    () => petStore.getPetById(params.id || ""),
    [params?.id, petStore]
  );

  return (
    <PageContainer name="Detalii porumbel" hasBackButton>
      <DetailActions />
      <div className="grid">
        <Card
          className="p-3 col-12 md:col-6 lg:col-4"
          insideClassName="bg-white p-3"
        >
          <div className="flex justify-content-center align-items-center h-full">
            <img
              src={pet.imgUrl || defaultImg}
              alt="Imaginea porumbelului"
              className="h-auto w-auto max-w-5rem max-h-5rem "
            />
          </div>
        </Card>
        <Card
          className="p-3 col-12 md:col-6 lg:col-4"
          insideClassName="bg-white p-3"
        >
          <DetailItem label="Nume" value={pet.name} />
          <DetailItem
            label="Inel"
            value={`${pet.country} - ${pet.ringNumber}`}
          />
          <DetailItem label="Status" value={pet.status} />
          <DetailItem
            label="Gen"
            value={pet.gender === "male" ? "Mascul" : "Femela"}
          />
        </Card>
        <Card
          className="p-3 col-12 md:col-6 lg:col-4"
          insideClassName="bg-white p-3"
        >
          <DetailItem label="Detalii" value={pet.description} />
        </Card>
      </div>
      <Divider />
      <div className="grid">
        <Card className="col-12" insideClassName="bg-white p-3">
          <Contests contestList={[]} />
        </Card>
      </div>
    </PageContainer>
  );
};
