import { useClickAway } from "hooks/useClickAway";
import { classNames } from "primereact/utils";
import { useRef, useState } from "react";
import { PetActions } from "../pet-actions/pet-actions";
import { ReactComponent as NotAllowed } from "../../../../assets/svg/notAllowed.svg";
import "./pet-card.scss";

interface Props {
  pet: any;
  height: number;
  setIsAddingPet: () => void;
  isTop?: boolean;
  isBottom?: boolean;
  hasNext?: boolean;
  shouldHidePlusSigns?: boolean;
  isDisabled?: boolean;
}

const defaultFontSize = 12;

export const PetCard = ({
  pet,
  height = 120,
  isTop = false,
  isBottom = false,
  hasNext = true,
  shouldHidePlusSigns = false,
  isDisabled = false,
  setIsAddingPet,
}: Props) => {
  const [isActionsVisible, setIsActionsVisible] = useState(false);
  const [bgColor, setBgColor] = useState("#fff");
  const [nameSize, setNameSize] = useState(defaultFontSize);
  const [descriptionSize, setDescriptionSize] = useState(defaultFontSize);

  const cardRef = useRef(null);

  useClickAway(cardRef, () => setIsActionsVisible(false));
  const isDisplayingPlus = !pet;

  return (
    <div
      className="pet-card-container"
      onClick={() => setIsActionsVisible(!isActionsVisible)}
      ref={cardRef}
    >
      {isDisplayingPlus && !shouldHidePlusSigns && (
        <div
          className={classNames("pet-card__plus", {
            "pet-card__disabled": isDisabled,
          })}
          onClick={() => setIsAddingPet()}
        >
          {!isDisabled && "+"}
          {isDisabled && <NotAllowed className="pet-card__not-allowed" />}
        </div>
      )}
      <PetActions
        isVisible={
          isActionsVisible && !shouldHidePlusSigns && !isDisplayingPlus
        }
        petData={pet}
        bgColor={bgColor}
        setBgColor={setBgColor}
        nameSize={nameSize}
        setNameSize={setNameSize}
        descriptionSize={descriptionSize}
        setDescriptionSize={setDescriptionSize}
      />
      <div
        className={classNames("pet-card border-round-sm card bg-white", {
          "pet-card__top": isTop,
          "pet-card__bottom": isBottom,
          "pet-card__has-next": hasNext,
        })}
        style={{ height }}
      >
        <p
          className="pet-card__name border-round-sm border-noround-bottom font-bold text-blue-500 text-center bg-gray-300"
          style={{
            fontSize: `${nameSize}px`,
            backgroundColor: bgColor,
          }}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${(
              pet?.country || ""
            ).toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${(
              pet?.country || ""
            ).toLowerCase()}.png 2x`}
            alt=""
            className="mr-2 radius-50"
          />
          <span>
            {pet?.country || ""}-{pet?.ringNumber || ""}
          </span>
        </p>
        <p
          className="pet-card__description border-round-sm border-noround-top"
          style={{
            fontSize: `${descriptionSize}px`,
            backgroundColor: bgColor,
          }}
        >
          <span className="text-blue-500 w-full text-center m-0 mb-2 text-sm font-medium">
            {pet?.name || ""}
          </span>{" "}
          <br />
          {pet?.description || ""}
        </p>
      </div>
      <div className="pet-card__shadow border-round-sm" style={{ height }} />
    </div>
  );
};
