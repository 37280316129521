import { Card } from "components/card/card-component";
import React from "react";

export const Dashboard = () => {
  return (
    <div className="grid">
      <div className="col">
        <Card
          className="text-blue-500 font-bold"
          insideClassName="bg-white p-3"
        >
          1 <br />
          2 <br />
          3<br />
        </Card>
      </div>
      <div className="col">
        <Card insideClassName="bg-white p-3">1</Card>
      </div>
      <div className="col">
        <Card insideClassName="bg-white p-3">1</Card>
      </div>
    </div>
  );
};
