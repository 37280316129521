import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
import { useState } from "react";
import styles from "../login/login.module.scss";
import icon from "../../assets/png/icon.png";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routeConstants";

export const ConfirmEmail = () => {
  const [isSendCodeVisible, setIsSendCodeVisible] = useState(false);
  const navigate = useNavigate();

  const goToAuth = () => {
    navigate(ROUTE_CONSTANTS.LOGIN, { replace: true });
  };

  return (
    <div
      className={`flex flex-1 bg-cyan-50 m-auto w-full h-full max-h-full overflow-auto p-5 ${styles.container}`}
    >
      <div className="card bg-white w-20rem shadow-4 border-round-lg flex justify-content-center align-items-center m-auto flex-column">
        <Avatar image={icon} className="mr-2 mt-3" size="xlarge" />
        <h2 className="text-blue-800 w-15rem">
          Te felicitam pentru crearea contului!
        </h2>
        {!isSendCodeVisible && (
          <>
            <h4 className="text-blue-800 w-15rem">
              Te rugam sa verifici in inbox sau spam si sa confirmi adresa de
              e-mail apoi vei putea continua cu autentificarea.
            </h4>
            <Button
              onClick={goToAuth}
              className="m-2 p-button-sm w-15rem justify-content-center h-2rem mb-5"
            >
              Mergi la login
            </Button>
            <div className="flex w-15rem justify-content-center mb-5">
              <span>Nu ai codul?</span>
              <p
                onClick={() => setIsSendCodeVisible(true)}
                className="p-button-text text-blue-600 font-bold p-0 m-0"
              >
                trimite cod!
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
