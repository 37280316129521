import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { ProfileSection } from "./profile-section";
import profile from "../../assets/png/profile.png";
import { Divider } from "primereact/divider";

export const Profile = observer(() => {
  const { authStore } = useStore();
  const { user } = authStore;

  return (
    <div className="w-full h-full bg-white border-round-xl shadow-4 p-3 overflow-hidden">
      <h2 className="text-blue-800 m-0 mb-4">Profil</h2>
      <Divider />
      <div className="grid h-full ">
        <div className="col-fixed w-8rem">
          <img
            src={profile}
            className="w-full border-circle border-round-xl surface-2"
            alt="test"
          />
        </div>
        <div className="col h-full overflow-auto">
          <div className="grid text-blue-800">
            <div className="col-11">
              <ProfileSection label="Nume" value={user?.lastName || ""} />
            </div>
            <div className="col-11">
              <ProfileSection label="Prenume" value={user?.firstName || ""} />
            </div>
            <div className="col-11">
              <ProfileSection label="Email" value={user?.email || ""} />
            </div>
            <div className="col-11">
              <ProfileSection label="Adresa" value={user?.address || ""} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
