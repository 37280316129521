export const countries = [
  // { value: 'AD', name: 'Andorra', phone: '376' },
  // {
  //   value: 'AE',
  //   name: 'United Arab Emirates',
  //   phone: '971'
  // },
  // { value: 'AF', name: 'Afghanistan', phone: '93' },
  // {
  //   value: 'AG',
  //   name: 'Antigua and Barbuda',
  //   phone: '1-268'
  // },
  // { value: 'AI', name: 'Anguilla', phone: '1-264' },
  // { value: 'AL', name: 'Albania', phone: '355' },
  // { value: 'AM', name: 'Armenia', phone: '374' },
  // { value: 'AO', name: 'Angola', phone: '244' },
  // { value: 'AQ', name: 'Antarctica', phone: '672' },
  // { value: 'AR', name: 'Argentina', phone: '54' },
  // { value: 'AS', name: 'American Samoa', phone: '1-684' },
  { value: "AT", name: "Austria", phone: "43" },
  // {
  //   value: 'AU',
  //   name: 'Australia',
  //   phone: '61',
  //   suggested: true
  // },
  // { value: 'AW', name: 'Aruba', phone: '297' },
  // { value: 'AX', name: 'Alland Islands', phone: '358' },
  // { value: 'AZ', name: 'Azerbaijan', phone: '994' },
  {
    value: "BA",
    name: "Bosnia and Herzegovina",
    phone: "387",
  },
  // { value: 'BB', name: 'Barbados', phone: '1-246' },
  // { value: 'BD', name: 'Bangladesh', phone: '880' },
  { value: "BE", name: "Belgium", phone: "32" },
  // { value: 'BF', name: 'Burkina Faso', phone: '226' },
  { value: "BG", name: "Bulgaria", phone: "359" },
  // { value: 'BH', name: 'Bahrain', phone: '973' },
  // { value: 'BI', name: 'Burundi', phone: '257' },
  // { value: 'BJ', name: 'Benin', phone: '229' },
  // { value: 'BL', name: 'Saint Barthelemy', phone: '590' },
  // { value: 'BM', name: 'Bermuda', phone: '1-441' },
  // { value: 'BN', name: 'Brunei Darussalam', phone: '673' },
  // { value: 'BO', name: 'Bolivia', phone: '591' },
  // { value: 'BR', name: 'Brazil', phone: '55' },
  // { value: 'BS', name: 'Bahamas', phone: '1-242' },
  // { value: 'BT', name: 'Bhutan', phone: '975' },
  // { value: 'BV', name: 'Bouvet Island', phone: '47' },
  // { value: 'BW', name: 'Botswana', phone: '27' },
  // { value: 'BY', name: 'Belarus', phone: '375' },
  // { value: 'BZ', name: 'Belize', phone: '501' },
  {
    value: "CA",
    name: "Canada",
    phone: "1",
    suggested: true,
  },
  // {
  //   value: 'CC',
  //   name: 'Cocos (Keeling) Islands',
  //   phone: '61'
  // },
  // {
  //   value: 'CD',
  //   name: 'Congo, Democratic Republic of the',
  //   phone: '243'
  // },
  // {
  //   value: 'CF',
  //   name: 'Central African Republic',
  //   phone: '236'
  // },
  // {
  //   value: 'CG',
  //   name: 'Congo, Republic of the',
  //   phone: '242'
  // },
  { value: "CH", name: "Switzerland", phone: "41" },
  // { value: 'CI', name: "Cote d'Ivoire", phone: '225' },
  // { value: 'CK', name: 'Cook Islands', phone: '682' },
  // { value: 'CL', name: 'Chile', phone: '56' },
  // { value: 'CM', name: 'Cameroon', phone: '237' },
  // { value: 'CN', name: 'China', phone: '86' },
  // { value: 'CO', name: 'Colombia', phone: '57' },
  // { value: 'CR', name: 'Costa Rica', phone: '506' },
  // { value: 'CU', name: 'Cuba', phone: '53' },
  // { value: 'CV', name: 'Cape Verde', phone: '238' },
  // { value: 'CW', name: 'Curacao', phone: '599' },
  // { value: 'CX', name: 'Christmas Island', phone: '61' },
  // { value: 'CY', name: 'Cyprus', phone: '357' },
  { value: "CZ", name: "Czech Republic", phone: "420" },
  {
    value: "DE",
    name: "Germany",
    phone: "49",
    suggested: true,
  },
  // { value: 'DJ', name: 'Djibouti', phone: '253' },
  // { value: 'DK', name: 'Denmark', phone: '45' },
  // { value: 'DM', name: 'Dominica', phone: '1-767' },
  // {
  //   value: 'DO',
  //   name: 'Dominican Republic',
  //   phone: '1-809'
  // },
  // { value: 'DZ', name: 'Algeria', phone: '213' },
  // { value: 'EC', name: 'Ecuador', phone: '593' },
  // { value: 'EE', name: 'Estonia', phone: '372' },
  // { value: 'EG', name: 'Egypt', phone: '20' },
  // { value: 'EH', name: 'Western Sahara', phone: '212' },
  // { value: 'ER', name: 'Eritrea', phone: '291' },
  { value: "ES", name: "Spain", phone: "34" },
  // { value: 'ET', name: 'Ethiopia', phone: '251' },
  { value: "FI", name: "Finland", phone: "358" },
  // { value: 'FJ', name: 'Fiji', phone: '679' },
  // {
  //   value: 'FK',
  //   name: 'Falkland Islands (Malvinas)',
  //   phone: '500'
  // },
  // {
  //   value: 'FM',
  //   name: 'Micronesia, Federated States of',
  //   phone: '691'
  // },
  // { value: 'FO', name: 'Faroe Islands', phone: '298' },
  {
    value: "FR",
    name: "France",
    phone: "33",
    suggested: true,
  },
  // { value: 'GA', name: 'Gabon', phone: '241' },
  { value: "GB", name: "United Kingdom", phone: "44" },
  // { value: 'GD', name: 'Grenada', phone: '1-473' },
  { value: "GE", name: "Georgia", phone: "995" },
  // { value: 'GF', name: 'French Guiana', phone: '594' },
  // { value: 'GG', name: 'Guernsey', phone: '44' },
  // { value: 'GH', name: 'Ghana', phone: '233' },
  // { value: 'GI', name: 'Gibraltar', phone: '350' },
  { value: "GL", name: "Greenland", phone: "299" },
  // { value: 'GM', name: 'Gambia', phone: '220' },
  // { value: 'GN', name: 'Guinea', phone: '224' },
  // { value: 'GP', name: 'Guadeloupe', phone: '590' },
  // { value: 'GQ', name: 'Equatorial Guinea', phone: '240' },
  { value: "GR", name: "Greece", phone: "30" },
  // {
  //   value: 'GS',
  //   name: 'South Georgia and the South Sandwich Islands',
  //   phone: '500'
  // },
  // { value: 'GT', name: 'Guatemala', phone: '502' },
  // { value: 'GU', name: 'Guam', phone: '1-671' },
  // { value: 'GW', name: 'Guinea-Bissau', phone: '245' },
  // { value: 'GY', name: 'Guyana', phone: '592' },
  // { value: 'HK', name: 'Hong Kong', phone: '852' },
  // {
  //   value: 'HM',
  //   name: 'Heard Island and McDonald Islands',
  //   phone: '672'
  // },
  // { value: 'HN', name: 'Honduras', phone: '504' },
  { value: "HR", name: "Croatia", phone: "385" },
  // { value: 'HT', name: 'Haiti', phone: '509' },
  { value: "HU", name: "Hungary", phone: "36" },
  { value: "ID", name: "Indonesia", phone: "62" },
  { value: "IE", name: "Ireland", phone: "353" },
  { value: "IL", name: "Israel", phone: "972" },
  // { value: 'IM', name: 'Isle of Man', phone: '44' },
  // { value: 'IN', name: 'India', phone: '91' },
  // {
  //   value: 'IO',
  //   name: 'British Indian Ocean Territory',
  //   phone: '246'
  // },
  // { value: 'IQ', name: 'Iraq', phone: '964' },
  // {
  //   value: 'IR',
  //   name: 'Iran, Islamic Republic of',
  //   phone: '98'
  // },
  // { value: 'IS', name: 'Iceland', phone: '354' },
  { value: "IT", name: "Italy", phone: "39" },
  // { value: 'JE', name: 'Jersey', phone: '44' },
  // { value: 'JM', name: 'Jamaica', phone: '1-876' },
  // { value: 'JO', name: 'Jordan', phone: '962' },
  // {
  //   value: 'JP',
  //   name: 'Japan',
  //   phone: '81',
  //   suggested: true
  // },
  // { value: 'KE', name: 'Kenya', phone: '254' },
  // { value: 'KG', name: 'Kyrgyzstan', phone: '996' },
  // { value: 'KH', name: 'Cambodia', phone: '855' },
  // { value: 'KI', name: 'Kiribati', phone: '686' },
  // { value: 'KM', name: 'Comoros', phone: '269' },
  // {
  //   value: 'KN',
  //   name: 'Saint Kitts and Nevis',
  //   phone: '1-869'
  // },
  // {
  //   value: 'KP',
  //   name: "Korea, Democratic People's Republic of",
  //   phone: '850'
  // },
  // { value: 'KR', name: 'Korea, Republic of', phone: '82' },
  // { value: 'KW', name: 'Kuwait', phone: '965' },
  // { value: 'KY', name: 'Cayman Islands', phone: '1-345' },
  // { value: 'KZ', name: 'Kazakhstan', phone: '7' },
  // {
  //   value: 'LA',
  //   name: "Lao People's Democratic Republic",
  //   phone: '856'
  // },
  // { value: 'LB', name: 'Lebanon', phone: '961' },
  // { value: 'LC', name: 'Saint Lucia', phone: '1-758' },
  // { value: 'LI', name: 'Liechtenstein', phone: '423' },
  // { value: 'LK', name: 'Sri Lanka', phone: '94' },
  // { value: 'LR', name: 'Liberia', phone: '231' },
  // { value: 'LS', name: 'Lesotho', phone: '266' },
  // { value: 'LT', name: 'Lithuania', phone: '370' },
  { value: "LU", name: "Luxembourg", phone: "352" },
  // { value: 'LV', name: 'Latvia', phone: '371' },
  // { value: 'LY', name: 'Libya', phone: '218' },
  { value: "MA", name: "Morocco", phone: "212" },
  { value: "MC", name: "Monaco", phone: "377" },
  {
    value: "MD",
    name: "Moldova, Republic of",
    phone: "373",
  },
  // { value: 'ME', name: 'Montenegro', phone: '382' },
  // {
  //   value: 'MF',
  //   name: 'Saint Martin (French part)',
  //   phone: '590'
  // },
  // { value: 'MG', name: 'Madagascar', phone: '261' },
  // { value: 'MH', name: 'Marshall Islands', phone: '692' },
  // {
  //   value: 'MK',
  //   name: 'Macedonia, the Former Yugoslav Republic of',
  //   phone: '389'
  // },
  // { value: 'ML', name: 'Mali', phone: '223' },
  // { value: 'MM', name: 'Myanmar', phone: '95' },
  // { value: 'MN', name: 'Mongolia', phone: '976' },
  // { value: 'MO', name: 'Macao', phone: '853' },
  // {
  //   value: 'MP',
  //   name: 'Northern Mariana Islands',
  //   phone: '1-670'
  // },
  // { value: 'MQ', name: 'Martinique', phone: '596' },
  // { value: 'MR', name: 'Mauritania', phone: '222' },
  // { value: 'MS', name: 'Montserrat', phone: '1-664' },
  { value: "MT", name: "Malta", phone: "356" },
  // { value: 'MU', name: 'Mauritius', phone: '230' },
  // { value: 'MV', name: 'Maldives', phone: '960' },
  // { value: 'MW', name: 'Malawi', phone: '265' },
  // { value: 'MX', name: 'Mexico', phone: '52' },
  // { value: 'MY', name: 'Malaysia', phone: '60' },
  // { value: 'MZ', name: 'Mozambique', phone: '258' },
  // { value: 'NA', name: 'Namibia', phone: '264' },
  // { value: 'NC', name: 'New Caledonia', phone: '687' },
  // { value: 'NE', name: 'Niger', phone: '227' },
  // { value: 'NF', name: 'Norfolk Island', phone: '672' },
  // { value: 'NG', name: 'Nigeria', phone: '234' },
  // { value: 'NI', name: 'Nicaragua', phone: '505' },
  { value: "NL", name: "Netherlands", phone: "31" },
  { value: "NO", name: "Norway", phone: "47" },
  // { value: 'NP', name: 'Nepal', phone: '977' },
  // { value: 'NR', name: 'Nauru', phone: '674' },
  // { value: 'NU', name: 'Niue', phone: '683' },
  { value: "NZ", name: "New Zealand", phone: "64" },
  // { value: 'OM', name: 'Oman', phone: '968' },
  // { value: 'PA', name: 'Panama', phone: '507' },
  // { value: 'PE', name: 'Peru', phone: '51' },
  // { value: 'PF', name: 'French Polynesia', phone: '689' },
  // { value: 'PG', name: 'Papua New Guinea', phone: '675' },
  // { value: 'PH', name: 'Philippines', phone: '63' },
  // { value: 'PK', name: 'Pakistan', phone: '92' },
  { value: "PL", name: "Poland", phone: "48" },
  // {
  //   value: 'PM',
  //   name: 'Saint Pierre and Miquelon',
  //   phone: '508'
  // },
  // { value: 'PN', name: 'Pitcairn', phone: '870' },
  // { value: 'PR', name: 'Puerto Rico', phone: '1' },
  // {
  //   value: 'PS',
  //   name: 'Palestine, State of',
  //   phone: '970'
  // },
  { value: "PT", name: "Portugal", phone: "351" },
  // { value: 'PW', name: 'Palau', phone: '680' },
  // { value: 'PY', name: 'Paraguay', phone: '595' },
  // { value: 'QA', name: 'Qatar', phone: '974' },
  // { value: 'RE', name: 'Reunion', phone: '262' },
  { value: "RO", name: "Romania", phone: "40" },
  { value: "RS", name: "Serbia", phone: "381" },
  { value: "RU", name: "Russian Federation", phone: "7" },
  // { value: 'RW', name: 'Rwanda', phone: '250' },
  // { value: 'SA', name: 'Saudi Arabia', phone: '966' },
  // { value: 'SB', name: 'Solomon Islands', phone: '677' },
  // { value: 'SC', name: 'Seychelles', phone: '248' },
  // { value: 'SD', name: 'Sudan', phone: '249' },
  // { value: 'SE', name: 'Sweden', phone: '46' },
  // { value: 'SG', name: 'Singapore', phone: '65' },
  // { value: 'SH', name: 'Saint Helena', phone: '290' },
  { value: "SI", name: "Slovenia", phone: "386" },
  // {
  //   value: 'SJ',
  //   name: 'Svalbard and Jan Mayen',
  //   phone: '47'
  // },
  // { value: 'SK', name: 'Slovakia', phone: '421' },
  // { value: 'SL', name: 'Sierra Leone', phone: '232' },
  // { value: 'SM', name: 'San Marino', phone: '378' },
  // { value: 'SN', name: 'Senegal', phone: '221' },
  // { value: 'SO', name: 'Somalia', phone: '252' },
  // { value: 'SR', name: 'Suriname', phone: '597' },
  // { value: 'SS', name: 'South Sudan', phone: '211' },
  // {
  // value: 'ST',
  // name: 'Sao Tome and Principe',
  // phone: '239'
  // },
  // { value: 'SV', name: 'El Salvador', phone: '503' },
  // {
  // value: 'SX',
  // name: 'Sint Maarten (Dutch part)',
  // phone: '1-721'
  // },

  // {
  //   value: 'SY',
  //   name: 'Syrian Arab Republic',
  //   phone: '963'
  // },
  { value: "SZ", name: "Swaziland", phone: "268" },
  // {
  //   value: 'TC',
  //   name: 'Turks and Caicos Islands',
  //   phone: '1-649'
  // },
  // { value: 'TD', name: 'Chad', phone: '235' },
  // {
  //   value: 'TF',
  //   name: 'French Southern Territories',
  //   phone: '262'
  // },
  // { value: 'TG', name: 'Togo', phone: '228' },
  // { value: 'TH', name: 'Thailand', phone: '66' },
  // { value: 'TJ', name: 'Tajikistan', phone: '992' },
  // { value: 'TK', name: 'Tokelau', phone: '690' },
  // { value: 'TL', name: 'Timor-Leste', phone: '670' },
  // { value: 'TM', name: 'Turkmenistan', phone: '993' },
  // { value: 'TN', name: 'Tunisia', phone: '216' },
  // { value: 'TO', name: 'Tonga', phone: '676' },
  { value: "TR", name: "Turkey", phone: "90" },
  // {
  //   value: 'TT',
  //   name: 'Trinidad and Tobago',
  //   phone: '1-868'
  // },
  // { value: 'TV', name: 'Tuvalu', phone: '688' },
  // {
  //   value: 'TW',
  //   name: 'Taiwan, Province of China',
  //   phone: '886'
  // },
  // {
  //   value: 'TZ',
  //   name: 'United Republic of Tanzania',
  //   phone: '255'
  // },
  { value: "UA", name: "Ukraine", phone: "380" },
  // { value: 'UG', name: 'Uganda', phone: '256' },
  // {
  //   value: 'US',
  //   name: 'United States',
  //   phone: '1',
  //   suggested: true
  // },
  // { value: 'UY', name: 'Uruguay', phone: '598' },
  // { value: 'UZ', name: 'Uzbekistan', phone: '998' },
  // {
  //   value: 'VA',
  //   name: 'Holy See (Vatican City State)',
  //   phone: '379'
  // },
  // {
  //   value: 'VC',
  //   name: 'Saint Vincent and the Grenadines',
  //   phone: '1-784'
  // },
  // { value: 'VE', name: 'Venezuela', phone: '58' },
  // {
  //   value: 'VG',
  //   name: 'British Virgin Islands',
  //   phone: '1-284'
  // },
  // {
  //   value: 'VI',
  //   name: 'US Virgin Islands',
  //   phone: '1-340'
  // },
  // { value: 'VN', name: 'Vietnam', phone: '84' },
  // { value: 'VU', name: 'Vanuatu', phone: '678' },
  // { value: 'WF', name: 'Wallis and Futuna', phone: '681' },
  // { value: 'WS', name: 'Samoa', phone: '685' },
  // { value: 'XK', name: 'Kosovo', phone: '383' },
  // { value: 'YE', name: 'Yemen', phone: '967' },
  // { value: 'YT', name: 'Mayotte', phone: '262' },
  // { value: 'ZA', name: 'South Africa', phone: '27' },
  // { value: 'ZM', name: 'Zambia', phone: '260' },
  // { value: 'ZW', name: 'Zimbabwe', phone: '263' }
];
