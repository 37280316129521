import { FIREBASE_KEYS } from "constants/appConstants";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref as imgStoreRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

export class PetStore {
  rootStore: any;
  petList: any[];

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.petList = [];
  }

  get user() {
    return this.rootStore.authStore.user;
  }

  get petsFatherOptions() {
    return this.petList
      .filter((pet) => pet.gender === "male")
      .map((pet) => ({
        value: pet.pigeonId,
        label: `${pet.name}-${pet.ringNumber}- M`,
      }));
  }

  get petsMotherOptions() {
    return this.petList
      .filter((pet) => pet.gender === "female")
      .map((pet) => ({
        value: pet.pigeonId,
        label: `${pet.name}-${pet.ringNumber}- F`,
      }));
  }

  get pets() {
    return this.petList.filter((pet) => pet.status === "Activ");
  }

  getPetById(petId: string) {
    const parent = this.petList.find((pet) => pet.pigeonId === petId);

    return parent || null;
  }

  getPedigree(petId: string) {
    const pet = this.getPetById(petId);
    const motherRing = pet.mother;
    const fatherRing = pet.father;
    const gr1 = this.getPetById(fatherRing);
    const gr2 = this.getPetById(motherRing);
    const gr11 = this.getPetById(gr1?.father || "");
    const gr12 = this.getPetById(gr1?.mother || "");
    const gr21 = this.getPetById(gr2?.father || "");
    const gr22 = this.getPetById(gr2?.mother || "");
    const gr111 = this.getPetById(gr11?.father || "");
    const gr112 = this.getPetById(gr11?.mother || "");
    const gr121 = this.getPetById(gr12?.father || "");
    const gr122 = this.getPetById(gr12?.mother || "");
    const gr211 = this.getPetById(gr21?.father || "");
    const gr212 = this.getPetById(gr21?.mother || "");
    const gr221 = this.getPetById(gr22?.father || "");
    const gr222 = this.getPetById(gr22?.mother || "");

    return {
      pet,
      gr1,
      gr2,
      gr11,
      gr12,
      gr21,
      gr22,
      gr111,
      gr112,
      gr121,
      gr122,
      gr211,
      gr212,
      gr221,
      gr222,
    };
  }

  async getPigeons() {
    this.rootStore.loadingStore.setIsLoading("component", true);
    const db = getFirestore();
    const queryPets = await getDocs(
      collection(
        db,
        FIREBASE_KEYS.PETS,
        this.user.userId,
        FIREBASE_KEYS.PIGEONS
      )
    );

    const pigeons = queryPets.docs.map((doc) => ({
      ...doc.data(),
      pigeonId: doc.id,
    }));

    this.petList = pigeons;
    this.rootStore.loadingStore.setIsLoading("component", false);
  }

  updatePigeon(pet: any) {
    const index = this.petList.findIndex((p) => p.pigeonId === pet.pigeonId);
    if (index === -1) return;
    this.petList[index] = pet;
  }

  async createPigeon(pet: any, closeForm: Function, file: any) {
    const { country, ringNumber, year } = pet;
    this.rootStore.loadingStore.setIsLoading("button", true);
    const db = getFirestore();
    const petRef = doc(
      collection(
        db,
        FIREBASE_KEYS.PETS,
        this.user.userId,
        FIREBASE_KEYS.PIGEONS
      )
    );
    const storage = getStorage();
    const ringCode = `${country}-${ringNumber}-${year}`;
    let storageRef;
    if (file) {
      storageRef = imgStoreRef(
        storage,
        `${this.user.userId}/${FIREBASE_KEYS.PIGEONS}/${ringCode}/${file.name}`
      );
    }
    try {
      let imgUrl = "";

      if (storageRef) {
        await uploadBytes(storageRef, file);

        await getDownloadURL(storageRef).then((url) => {
          imgUrl = url;
        });
      }

      await setDoc(petRef, { ...pet, imgUrl });
      this.petList.push({ ...pet, imgUrl });
      this.rootStore.loadingStore.setIsLoading("button", false);
    } catch (err) {
      this.rootStore.loadingStore.setIsLoading("button", false);
    }
    closeForm();
  }

  async createParent({
    pet,
    closeForm,
    parent,
  }: {
    pet: any;
    closeForm: Function;
    parent: any;
  }) {
    this.rootStore.loadingStore.setIsLoading("button", true);
    const db = getFirestore();

    const petRef = collection(
      db,
      FIREBASE_KEYS.PETS,
      this.user.userId,
      FIREBASE_KEYS.PIGEONS
    );
    try {
      const docRef = await addDoc(petRef, parent);
      const updateRef = doc(
        db,
        FIREBASE_KEYS.PETS,
        this.user.userId,
        FIREBASE_KEYS.PIGEONS,
        pet.pigeonId
      );
      const updatedPet = {
        ...pet,
        ...(parent.gender === "male" && { father: docRef.id }),
        ...(parent.gender === "female" && { mother: docRef.id }),
      };

      await updateDoc(updateRef, {
        ...(parent.gender === "male" && { father: docRef.id }),
        ...(parent.gender === "female" && { mother: docRef.id }),
      });

      this.updatePigeon(updatedPet);

      this.petList.push({
        ...parent,
        pigeonId: docRef.id,
      });

      this.rootStore.loadingStore.setIsLoading("button", false);
      closeForm();
    } catch (error) {
      this.rootStore.loadingStore.setIsLoading("button", false);
      console.log("error", error);
    }
  }
}
