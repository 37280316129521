import { useEffect } from "react";

export const useClickAway = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      const dropdownSelect = document.querySelector(".p-dropdown-panel");
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        dropdownSelect?.contains(event.target)
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
