import { Button } from "components/button/button";

export const DetailActions = () => {
  return (
    <div className="w-full flex flex-col justify-content-end mb-4">
      <Button icon="pi pi-tag" label="Adauga Licitatie" onClick={() => {}} />
      <Button
        icon="pi pi-shopping-bag"
        label="Vinde"
        variant="outlined"
        onClick={() => {}}
      />
    </div>
  );
};
