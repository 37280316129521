import React, { useEffect, useState } from "react";
import { Toolbar } from "../../features/toolbar/toolbar";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import { Sidebar } from "../../features/sidebar/sidebar";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./home-page.module.scss";
import { useWindowSize } from "hooks/useWindowSize";
import { ALWAYS_SHOW_MENU_FROM_WIDTH } from "constants/appConstants";
import { classNames } from "primereact/utils";

export function AppLayout() {
  const windowSize = useWindowSize();
  const shouldStartWithOpenMenu =
    (windowSize.width || 0) > ALWAYS_SHOW_MENU_FROM_WIDTH;
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const location = useLocation();

  const setMenu = (isOpen: boolean) => {
    setSideMenuOpen(true);
  };

  useEffect(() => {
    setSideMenuOpen(shouldStartWithOpenMenu);
  }, [windowSize, shouldStartWithOpenMenu]);

  return (
    <div id="main-container" className={styles.homePage}>
      <div
        className={`${styles.homePageContainer}  ${
          location.pathname.includes("auction-list") ||
          location.pathname.includes("pet-sale")
            ? styles.homePageContainerSmall
            : ""
        } `}
      >
        <Sidebar
          isOpen={sideMenuOpen}
          defaultOpen={!shouldStartWithOpenMenu}
          setIsSidebarOpen={(isOpen: boolean) =>
            shouldStartWithOpenMenu ? () => {} : setSideMenuOpen(isOpen)
          }
        />
        <div
          className={classNames(styles.homePageContent, "p-3", {
            [styles.homePageOpenMaxWidth]:
              shouldStartWithOpenMenu && sideMenuOpen,
            [styles.homePageMaxWidth]: !shouldStartWithOpenMenu,
          })}
          onClick={() =>
            shouldStartWithOpenMenu ? () => {} : () => setSideMenuOpen(false)
          }
        >
          <Toolbar
            setSideMenuOpen={setMenu}
            sideMenuOpen={sideMenuOpen}
            defaultOpen={!shouldStartWithOpenMenu}
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
