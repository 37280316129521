import React, { useEffect, useState } from "react";
import { PageContainer } from "components/page-container/page-container";
import { observer } from "mobx-react-lite";
import { DataViewLayoutOptions, DataViewLayoutType } from "primereact/dataview";
import { useStore } from "store";
import { itemTemplate } from "./item-template/item-template";
import { Button } from "components/button/button";
import { AddEditAuction } from "features/add-edit-auction/add-edit-auction";
import { classNames } from "primereact/utils";

export const AuctionList = observer(() => {
  const [isAddingAuction, setIsAddingAuction] = useState(false);
  const [selectedTab, setSelectedTab] = useState("active");
  const { auctionStore, loadingStore } = useStore();
  const { auctions } = auctionStore;
  const { isLoading } = loadingStore;
  const [layout, setLayout] = useState<DataViewLayoutType>("grid");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  const tabAction = (
    <div
      className={classNames("flex h-100 align-items-center border-round-xl", {
        "shadow-6": windowWidth < 590,
      })}
    >
      <div
        className={classNames(
          " px-3 py-2 cursor-pointer h-100 border-round-left-xl border-right-1 border-gray-300",
          {
            "font-bold text-white bg-green-500": selectedTab === "active",
            "bg-white": selectedTab !== "active",
          }
        )}
        onClick={() => setSelectedTab("active")}
      >
        Active
      </div>
      <div
        className={classNames(" px-3 py-2 cursor-pointer h-100", {
          "font-bold text-white bg-green-500": selectedTab === "upcoming",
          " bg-white": selectedTab !== "upcoming",
        })}
        onClick={() => setSelectedTab("upcoming")}
      >
        Viitoare
      </div>
      <div
        className={classNames(
          " px-3 py-2 cursor-pointer h-100 border-round-right-xl border-left-1 border-gray-300",
          {
            "font-bold text-white bg-green-500": selectedTab === "finished",
            "bg-white": selectedTab !== "finished",
          }
        )}
        onClick={() => setSelectedTab("finished")}
      >
        Finalizate
      </div>
    </div>
  );

  const header = (
    <div className="flex justify-content-between mb-3 px-2 ">
      {windowWidth >= 590 && tabAction}

      <div className="flex justify-content-end align-items-center">
        <DataViewLayoutOptions
          layout={layout}
          onChange={(e) => setLayout(e.value)}
          // className="h-3rem"
        />
        <Button
          label="Adauga licitatie"
          onClick={() => setIsAddingAuction(true)}
        />
      </div>
    </div>
  );

  const footer = (
    <div className="absolute bottom-0 flex w-full justify-content-center mb-2">
      {tabAction}
    </div>
  );

  useEffect(() => {
    if (!isLoading.component) {
      auctionStore.getAuctions();
    }
  }, []); //eslint-disable-line

  return (
    <PageContainer name="Licitatii">
      <div className="sm:pb-3">
        {header}
        <div className="grid">
          {auctions
            .filter((auction) => auction.status === selectedTab)
            .map((auction) => {
              return itemTemplate(auction, layout);
            })}
        </div>
        {windowWidth < 590 && footer}
      </div>
      <AddEditAuction
        visible={isAddingAuction}
        onHide={() => setIsAddingAuction(false)}
      />
    </PageContainer>
  );
});
