import { PageContainer } from "components/page-container/page-container";
import { observer } from "mobx-react-lite";
import { AddEditPet } from "features/add-edit-pet/add-edit-pet";
import { PetCard } from "pages/pet-pedigree/components/pet-card/pet-card";
import { Button } from "components/button/button";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PDF from "react-to-pdf";
import { useStore } from "store";
import "./pet-pedigree.scss";

export const PetPedigree = observer(() => {
  const [shouldHideControls, setShouldHideControls] = useState(false);
  const [isAddingFor, setIsAddingFor] = useState<{ pet: any; gender: string }>({
    pet: null,
    gender: "",
  });
  const pedigreeRef = useRef(null);
  const { petStore, authStore } = useStore();
  const { user } = authStore;
  const { id } = useParams();
  const pedigree = petStore.getPedigree(id || "");

  const exportToPdf = async (exportFunction: any) => {
    setShouldHideControls(true);
    await exportFunction();
    setShouldHideControls(false);
  };

  return (
    <PageContainer name="Pedigree" hasBackButton>
      <>
        <div className="flex justify-content-end mb-2">
          <PDF targetRef={pedigreeRef} filename="pedigree.pdf">
            {({ toPdf }: { toPdf: any }) => (
              <Button
                label="Descarca"
                onClick={() => exportToPdf(toPdf)}
                iconPos="left"
                icon="pi pi-file-pdf"
              />
            )}
          </PDF>
        </div>
        <div className="pedigree-container flex w-full ">
          <div
            className="flex align-items-end pedigree m-auto relative bg-blue-50 "
            ref={pedigreeRef}
          >
            <div className="pedigree__title">
              <h3>
                <span>Pedigree porumbel: </span>
                <span className="text-blue-500">
                  {pedigree.pet.name}/{pedigree.pet.country}-
                  {pedigree.pet.ringNumber}
                </span>
              </h3>
            </div>
            <div className="flex align-items-center mb-2">
              <div className="">
                <PetCard
                  height={700}
                  pet={pedigree.pet}
                  setIsAddingPet={() => {}}
                />
              </div>
              <div>
                <PetCard
                  height={500}
                  isTop
                  pet={pedigree.gr1}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.pet.ringNumber}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.pet, gender: "male" })
                  }
                />
                <PetCard
                  height={500}
                  isBottom
                  pet={pedigree.gr2}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.pet.ringNumber}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.pet, gender: "female" })
                  }
                />
              </div>
              <div>
                <PetCard
                  height={250}
                  isTop
                  pet={pedigree.gr11}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr1}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr1, gender: "male" })
                  }
                />
                <PetCard
                  height={250}
                  isBottom
                  pet={pedigree.gr12}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr1}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr1, gender: "female" })
                  }
                />
                <PetCard
                  height={250}
                  isTop
                  pet={pedigree.gr21}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr2}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr2, gender: "male" })
                  }
                />
                <PetCard
                  height={250}
                  isBottom
                  pet={pedigree.gr22}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr2}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr2, gender: "female" })
                  }
                />
              </div>
              <div>
                <PetCard
                  height={125}
                  isTop
                  hasNext={false}
                  pet={pedigree.gr111}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr11}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr11, gender: "male" })
                  }
                />
                <PetCard
                  height={125}
                  isBottom
                  hasNext={false}
                  pet={pedigree.gr112}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr11}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr11, gender: "female" })
                  }
                />
                <PetCard
                  height={125}
                  isTop
                  hasNext={false}
                  pet={pedigree.gr121}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr12}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr12, gender: "male" })
                  }
                />
                <PetCard
                  height={125}
                  isBottom
                  hasNext={false}
                  pet={pedigree.gr122}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr12}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr12, gender: "female" })
                  }
                />
                <PetCard
                  height={125}
                  isTop
                  hasNext={false}
                  pet={pedigree.gr211}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr21}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr21, gender: "male" })
                  }
                />
                <PetCard
                  height={125}
                  isBottom
                  hasNext={false}
                  pet={pedigree.gr212}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr21}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr21, gender: "female" })
                  }
                />
                <PetCard
                  height={125}
                  isTop
                  hasNext={false}
                  pet={pedigree.gr221}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr22}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr22, gender: "male" })
                  }
                />
                <PetCard
                  height={125}
                  isBottom
                  hasNext={false}
                  pet={pedigree.gr222}
                  shouldHidePlusSigns={shouldHideControls}
                  isDisabled={!shouldHideControls && !pedigree.gr22}
                  setIsAddingPet={() =>
                    setIsAddingFor({ pet: pedigree.gr22, gender: "female" })
                  }
                />
              </div>
            </div>
            <div className="pedigree__details">
              <p className="m-0 p-0">
                <span className="text-gray-500 font-bold text-sm">
                  Crescator:
                </span>{" "}
                <br />
                <span className="font-bold text-sm">
                  {user?.lastName} {user?.firstName}
                </span>
              </p>
              <p className="m-0 p-0">
                <span className="text-gray-500 font-bold text-sm">
                  Contact:
                </span>{" "}
                <br />
                <span className="font-bold text-sm">
                  {user?.email} <br />
                  {user?.phoneNumber ? `${user?.phoneNumber}` : ""}
                </span>
              </p>
              <p className="m-0 p-0 text-sm mt-1">
                <span className="text-gray-500 font-bold">Semnatura:</span>
              </p>
              <p className="m-0 p-0">..........................</p>
            </div>
          </div>
        </div>
        <AddEditPet
          visible={!!isAddingFor.pet}
          setVisible={() => setIsAddingFor({ pet: null, gender: "" })}
          disableStatus={true}
          status="Inactive"
          defaultGender={isAddingFor.gender}
          child={isAddingFor.pet}
          isAddingParent
        />
      </>
    </PageContainer>
  );
});
