import { PageContainer } from "components/page-container/page-container";
import { observer } from "mobx-react-lite";

export const Contest = observer(() => {
  return (
    <PageContainer name="Concursuri">
      <span> test </span>
    </PageContainer>
  );
});
