import { makeAutoObservable } from "mobx";

export class LoadingStore {
  isLoading = {
    component: false,
    button: false,
    googleButton: false,
  };
  toastRef: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (key: keyof typeof this.isLoading, isLoading: boolean) => {
    this.isLoading[key] = isLoading;
  };

  setToastRef = (ref: any) => {
    this.toastRef = ref;
  };
}
