import { ColorSelect } from "components/color-select/color-select";
import { ROUTE_CONSTANTS } from "constants/routeConstants";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import "./pet-actions.scss";

interface IProps {
  isVisible: boolean;
  petData: any;
  bgColor: string;
  nameSize: number;
  descriptionSize: number;
  setBgColor: (color: string) => void;
  setNameSize: (size: number) => void;
  setDescriptionSize: (size: number) => void;
}

export const PetActions = ({
  isVisible,
  petData,
  bgColor,
  nameSize,
  descriptionSize,
  setBgColor,
  setDescriptionSize,
  setNameSize,
}: IProps) => {
  const navigate = useNavigate();

  const updateNameSize = (e: any) => {
    setNameSize(parseInt(e.target.value) || 12);
  };

  const updateDescriptionSize = (e: any) => {
    setDescriptionSize(parseInt(e.target.value) || 12);
  };

  return (
    <div
      className={classNames("absolute pet-actions-container", {
        "pet-actions-container--visible": isVisible,
      })}
      onClick={(e) => e.stopPropagation()}
      onTouchEnd={(e) => e.stopPropagation()}
    >
      <div className="pet-actions-container__element">
        <span>Culoare: </span>
        <ColorSelect selectedColor={bgColor} handleChange={setBgColor} />
      </div>
      <Divider />
      <div className="pet-actions-container__element">
        <span>Font nume: </span>
        <InputText
          value={nameSize}
          onChange={updateNameSize}
          className="pet-actions-container__input"
        />
      </div>
      <Divider />
      <div className="pet-actions-container__element">
        <span>Font descriere: </span>
        <InputText
          value={descriptionSize}
          onChange={updateDescriptionSize}
          className="pet-actions-container__input"
        />
      </div>
      <Divider />
      <div className="flex justify-content-between">
        <Button
          label="Edit"
          className="w-5rem h-2rem ml-2 mr-2 flex justify-content-center p-button-primary"
          icon="pi pi-pencil"
          iconPos="left"
          onClick={() => navigate(ROUTE_CONSTANTS.PET_DETAIL)}
        />
        <Button
          label="Detalii"
          className="w-5rem h-2rem ml-2 mr-2 flex justify-content-center p-button-success p-button-outlined"
          icon="pi pi-info-circle"
          iconPos="left"
          onClick={() =>
            navigate(
              `/${ROUTE_CONSTANTS.PET_LIST}/${ROUTE_CONSTANTS.PET_DETAIL}/${petData.pigeonId}`
            )
          }
        />
      </div>
    </div>
  );
};
