import { Button } from "components/button/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../login/login.module.scss";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { useStore } from "store";
import { Message } from "primereact/message";
import { observer } from "mobx-react-lite";
import { ROUTE_CONSTANTS } from "constants/routeConstants";

const defaultValues = {
  email: "",
  name: "",
  lastName: "",
  address: "",
  password: "",
  confirmPassword: "",
};

export const Signup = observer(() => {
  const { authStore, loadingStore } = useStore();
  const { isLoading } = loadingStore;
  const { loginError } = authStore;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });

  const onSubmit = (formData: any) => {
    authStore.createUser(formData, navigate, reset);
  };

  const getFormErrorMessage = (
    name:
      | "email"
      | "password"
      | "name"
      | "lastName"
      | "password"
      | "confirmPassword"
  ) => {
    return (
      errors[name] && <small className="p-error">{errors[name]?.message}</small>
    );
  };

  const loginWithGoogle = () => {
    authStore.logInWithGoogle(reset, navigate);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-1 bg-cyan-50 m-auto w-full h-full max-h-full overflow-auto p-5 ${styles.container}`}
    >
      <div className="card bg-white w-22rem shadow-4 border-round-lg flex justify-content-center align-items-center m-auto flex-column">
        <h2 className="text-blue-800 mb-2">Creaza cont!</h2>

        <div className="m-2">
          <span className="p-input-icon-left">
            <i className="pi pi-at" />
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email-ul este obligatoriu!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "E-mail invalid!",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  {...field}
                  // onChange={(e) => setEmail(e.target.value)}
                  placeholder="e-mail"
                  className={classNames("p-inputtext-sm w-17rem shadow-2", {
                    "p-invalid": fieldState.error,
                  })}
                />
              )}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("email")}</div>
        </div>
        <div className="m-2">
          <span className="p-input-icon-left">
            <i className="pi pi-user" />
            <Controller
              control={control}
              name="name"
              rules={{
                required: "Prenumele este obligatoriu!",
              }}
              render={({ field, fieldState }) => (
                <InputText
                  {...field}
                  placeholder="Prenume"
                  className={classNames("p-inputtext-sm w-17rem shadow-2", {
                    "p-invalid": fieldState.error,
                  })}
                />
              )}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("name")}</div>
        </div>
        <div className="m-2">
          <span className="p-input-icon-left ">
            <i className="pi pi-user" />
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: "Numele este obligatoriu!",
              }}
              render={({ field, fieldState }) => (
                <InputText
                  {...field}
                  placeholder="Nume"
                  className={classNames("p-inputtext-sm w-17rem shadow-2", {
                    "p-invalid": fieldState.error,
                  })}
                />
              )}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("lastName")}</div>
        </div>
        <span className="m-2 ">
          {/* <i className="pi pi-user" /> */}
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <InputTextarea
                // rows={2}
                autoResize
                {...field}
                placeholder="Adresa"
                className="p-inputtext-sm w-17rem shadow-2"
              />
            )}
          />
        </span>
        <div className="m-2">
          <span className="p-input-icon-left p-input-icon-right ">
            <i className="pi pi-key" />
            <Controller
              control={control}
              name="password"
              rules={{
                required: "Introdu o parola!",
              }}
              render={({ field, fieldState }) => (
                <InputText
                  {...field}
                  placeholder="Parola"
                  type={showPassword ? "text" : "password"}
                  className={classNames("w-17rem shadow-2", {
                    "p-invalid": fieldState.error,
                  })}
                />
              )}
            />
            <i
              className={`pi ${
                showPassword ? "pi-eye-slash" : "pi-eye"
              } cursor-pointer`}
              onClick={() => setShowPassword(!showPassword)}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("password")}</div>
        </div>
        <div className="m-2">
          <span className="p-input-icon-left p-input-icon-right">
            <i className="pi pi-key" />
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                required: "Introdu din nou parola!",
                validate: (value) =>
                  value === watch("password") || "Parolele nu se potrivesc",
              }}
              render={({ field, fieldState }) => (
                <InputText
                  {...field}
                  placeholder="Confirmare parola"
                  type={showConfirmPassword ? "text" : "password"}
                  className={classNames("w-17rem shadow-2", {
                    "p-invalid": fieldState.error,
                  })}
                />
              )}
            />
            <i
              className={`pi ${
                showConfirmPassword ? "pi-eye-slash" : "pi-eye"
              } cursor-pointer`}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("confirmPassword")}</div>
        </div>
        <div className="flex m-2 justify-content-between w-17rem">
          <Button
            type="submit"
            label="Creaza Cont"
            loading={isLoading.button}
            onClick={() => {}}
          />
          <Button
            type="button"
            loading={isLoading.button}
            label="Renunta"
            onClick={() => navigate(ROUTE_CONSTANTS.AUCTIONS)}
            variant="danger"
          />
        </div>
        {loginError && <Message severity="error" text={loginError} />}

        <Divider align="center" className="w-17rem font-bold m-2">
          <span className="p-tag">sau</span>
        </Divider>
        <Button
          label=""
          onClick={loginWithGoogle}
          loading={isLoading.googleButton}
          variant="outlined"
        >
          <i className="pi pi-google px-2"></i>
          <span className="px-3">Creaza cu Google </span>
        </Button>
        <div className="flex w-17rem justify-content-center m-2">
          <span>Ai deja cont?</span>
          <Link
            className="no-underline text-blue-600 font-bold mb-4"
            to="/login"
          >
            {" "}
            mergi la login!{" "}
          </Link>
        </div>
      </div>
    </form>
  );
});
