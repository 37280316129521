interface Props {
  className?: string;
  children: any;
  insideClassName?: string;
}

export const Card = ({
  className = "",
  children,
  insideClassName = "p-4",
}: Props) => {
  return (
    <div className={`${className}  `}>
      <div
        className={`${insideClassName} border-round-3xl h-full w-full shadow-2 `}
      >
        {children}
      </div>
    </div>
  );
};
