import "./color-select.scss";
import { colors } from "../../constants/colors";
import { Dropdown } from "primereact/dropdown";

interface IProps {
  selectedColor?: string;
  handleChange: (color: string) => void;
  isDisplayingLabel?: boolean;
}

export const ColorSelect = ({ selectedColor = "", handleChange }: IProps) => {
  const colorsMap = colors.map((color) => ({
    label: color,
    value: color,
  }));

  const optionTemplate = (option: any) => {
    return (
      <div
        className="color-select__color"
        style={{ backgroundColor: option.value }}
      />
    );
  };

  return (
    <div className="color-container">
      <Dropdown
        className="color-container__select"
        options={colorsMap}
        optionLabel="label"
        optionValue="value"
        height={20}
        value=""
        style={{ backgroundColor: selectedColor }}
        itemTemplate={optionTemplate}
        onChange={(e) => handleChange(e.target.value)}
      ></Dropdown>
    </div>
  );
};
