import dayjs from "dayjs";
import { Button } from "components/button/button";
import { useNavigate } from "react-router-dom";
import userImg from "../../../assets/png/profile.png";
import "./item-template.scss";
import { Card } from "components/card/card-component";
import { CountDownTimer } from "components/count-down/count-down";
import { ReactComponent as ClockIcon } from "assets/svg/clock-fill.svg";

export const ListItem = ({ auction }: { auction: any }) => {
  const navigate = useNavigate();

  return (
    <Card
      className="col-12 p-3 md:h-12rem h-20rem flex"
      insideClassName="bg-green-100"
    >
      <div className="flex justify-content-center  md:justify-content-between flex-column md:flex-row  md:h-12rem h-20rem flex p-3">
        <div className="h-8rem flex">
          <img
            src={userImg}
            className="max-w-full max-h-full card-image border-round-2xl"
            alt="user selected"
          />
          <div className="ml-3">
            <p className="font-bold text-lg m-0">
              {auction.user.firstName} {auction.user.lastName}
            </p>
            <p className="font-bold text-lg m-0 text-gray-400 mb-1">
              Sloturi: 10
            </p>
            <div className="bg-blur text-blue-700 font-bold font-bold border-round-3xl card-counter px-2 gap-2 flex align-items-center justify-content-center h-2rem">
              <ClockIcon />
              <CountDownTimer milliseconds={10000000} />
            </div>
          </div>
        </div>
        <div className="flex flex-column justify-content-between h-8rem">
          <div className="flex flex-column">
            <p className="m-0 font-bold mb-1">
              <span className="text-gray-400">Start: </span>
              <span className="text-blue-700">
                {dayjs(auction.startDate).format("DD/MM/YYYY HH:mm")}
              </span>
            </p>
            <p className="m-0 font-bold mb-1">
              <span className="text-gray-400">Finish: </span>
              <span className="text-blue-700">
                {dayjs(auction.endDate).format("DD/MM/YYYY HH:mm")}
              </span>
            </p>
          </div>
          <Button
            label="Vezi licitatie"
            variant="contained"
            onClick={() => navigate(`${auction.auctionId}`)}
          />
        </div>
      </div>
    </Card>
  );
};

export const GridItem = ({ auction }: { auction: any }) => {
  const navigate = useNavigate();

  return (
    <Card
      className="col-12 sm:col-6 lg:col-4 xl:col-4 h-22rem p-3  "
      insideClassName="p-0 bg-green-100 relative"
    >
      <div className="h-3rem p-3 md:p-2 sm:p-1">
        <div className="bg-blur text-blue-700 font-bold border-round-3xl absolute top-0 mt-2 left-0 ml-2 card-counter px-2 gap-2 flex align-items-center justify-content-center h-2rem">
          <ClockIcon />

          <CountDownTimer milliseconds={10000000} />
        </div>
      </div>
      <header
        className={"h-17rem flex justify-content-center pl-5 pr-5 pb-5 pt-2"}
      >
        <img
          src={userImg}
          className="max-h-full max-w-full border-round-2xl card-image"
          alt="user selected"
        />
      </header>

      <div className="bg-blur flex justify-content-between h-5rem border-round-bottom-3xl align-items-center p-3 absolute bottom-0 w-full">
        <p className="m-0 h-full flex align-items-center font-bold text-gray-600 text-lg">
          {auction.user.firstName}
        </p>

        <Button
          label="Detalii"
          variant="contained"
          onClick={() => navigate(`${auction.auctionId}`)}
        />
      </div>
    </Card>
  );
};

export const itemTemplate = (auction: any, layout: any) => {
  if (!auction) {
    return;
  }

  if (layout === "list") return <ListItem auction={auction} />;
  else if (layout === "grid") return <GridItem auction={auction} />;
};
