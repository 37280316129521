import { Avatar } from "primereact/avatar";
import React from "react";
import styles from "../login/login.module.scss";
import icon from "../../assets/png/icon.png";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";

export const VerifyEmail = () => {
  const { authStore } = useStore();
  const navigate = useNavigate();

  const sendConfirmationEmail = () => {
    authStore.sendVerificationEmail();
  };
  const goToLogin = () => {
    navigate("/login", { replace: true });
  };

  return (
    <div
      className={`flex flex-1 bg-cyan-50 m-auto w-full h-full max-h-full overflow-auto p-5 ${styles.container}`}
    >
      <div className="card bg-white w-20rem shadow-4 border-round-lg flex justify-content-center align-items-center m-auto flex-column">
        <Avatar image={icon} className="mr-2 mt-3" size="xlarge" />

        <h3 className="text-blue-800 w-15rem m-2">
          Se pare ca ai omis sa confirmi e-mailul!
        </h3>

        <h5 className="text-blue-800 w-15rem m-1">
          Te rugam sa verifici casuta de e-mail sau casuta de spam si sa
          verifici e-mailul
        </h5>

        <div className="flex w-16rem justify-content-center mt-3">
          <span className="text-blue-800">Nu ai primit emailul?</span>
          <span
            className="no-underline text-blue-600 font-bold mb-3 cursor-pointer"
            onClick={sendConfirmationEmail}
          >
            trimite email!
          </span>
        </div>
        <Button
          type="submit"
          className="m-2 p-button-sm w-15rem justify-content-center h-2rem mb-5"
          label="Mergi la login"
          onClick={goToLogin}
        />
      </div>
    </div>
  );
};
