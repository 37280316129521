import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import { fieldType } from "../add-edit-pet";

interface IProps {
  field: fieldType;
  control: any;
  label: string;
  getFormErrorMessage: (name: fieldType) => JSX.Element | undefined;
  rules?: any;
}

export const FormInput = ({
  control,
  field,
  rules,
  label,
  getFormErrorMessage,
}: IProps) => {
  return (
    <div className="m-2 pb-3">
      <p className="m-0 mb-2 display-block">{label}</p>
      <span>
        <Controller
          name={field}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => (
            <InputText
              {...field}
              className={classNames("p-inputtext-sm w-15rem shadow-2", {
                "p-invalid": fieldState.error,
              })}
            />
          )}
        />
      </span>
      <div className="ml-3">{getFormErrorMessage(field)}</div>
    </div>
  );
};
