import { VIEW_TYPE } from "constants/appConstants";
import { makeAutoObservable } from "mobx";
import { viewType } from "model/view-type";

export class ViewStore {
  activeView: viewType = VIEW_TYPE.GRID as viewType;

  constructor() {
    makeAutoObservable(this);
  }

  setViewType(view: viewType) {
    this.activeView = view;
  }
}
