import React, { useEffect } from "react";

export const CountDownTimer = ({ milliseconds }: { milliseconds: number }) => {
  const hours = 0,
    minutes = 0,
    seconds = 0;

  const [[hrs, mins, secs], setTime] = React.useState([
    hours,
    minutes,
    seconds,
  ]);

  const msToTime = (duration: number) => {
    let seconds = parseInt(((duration / 1000) % 60).toString(), 10);
    let minutes = parseInt(((duration / (1000 * 60)) % 60).toString(), 10);
    let hours = parseInt(((duration / (1000 * 60 * 60)) % 24).toString(), 10);

    hours = hours < 10 ? 0 + hours : hours;
    minutes = minutes < 10 ? 0 + minutes : minutes;
    seconds = seconds < 10 ? 0 + seconds : seconds;

    setTime([hours, minutes, seconds]);
  };

  useEffect(() => msToTime(milliseconds), [milliseconds]);

  const tick = () => {
    if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };

  //   transform milliseconds into hours minutes and seconds

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <p className="m-0">{`${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`}</p>
  );
};
