import { createContext, useContext } from "react";
import { AuthStore } from "store/auth-store";
import { LoadingStore } from "store/loading-store";
import { AuctionStore } from "./auction-store";
import { PetStore } from "./pet-store";
import { ViewStore } from "./view-store";

class RootStore {
  authStore;
  loadingStore;
  petStore;
  viewStore;
  auctionStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.loadingStore = new LoadingStore();
    this.petStore = new PetStore(this);
    this.viewStore = new ViewStore();
    this.auctionStore = new AuctionStore(this);
  }
}

const StoreContext = createContext<RootStore>(new RootStore());

const StoreProvider = ({
  store,
  children,
}: {
  store: RootStore;
  children: any;
}) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export { RootStore, StoreProvider, useStore };
