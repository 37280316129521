import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "components/button/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Slider } from "primereact/slider";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { ReactComponent as Male } from "../../assets/svg/male.svg";
import { ReactComponent as Female } from "../../assets/svg/female.svg";
import { Header } from "components/header/header";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routeConstants";
import defaultImg from "../../assets/png/pigeon.png";
import { AddEditPet } from "features/add-edit-pet/add-edit-pet";
import { PageContainer } from "components/page-container/page-container";
import { Card } from "components/card/card-component";

export const PetList = observer(() => {
  const [isAddingPet, setIsAddingPet] = useState(false);
  const { petStore, loadingStore } = useStore();
  const { pets } = petStore;
  const navigate = useNavigate();

  const [filters, setFilters] = useState<any>(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    petStore.getPigeons();
  }, []); //eslint-disable-line

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "country.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      representative: { value: null, matchMode: FilterMatchMode.IN },
      date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      balance: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
      verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          onClick={initFilters}
        />
        <span className="p-input-icon-left ">
          <i className="pi pi-search" />
          <InputText
            className="w-10rem"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Cuvant cheie"
          />
        </span>
      </div>
    );
  };

  const genderBodyTemplate = (rowData: any) => {
    return rowData.gender === "male" ? (
      <div className="flex align-items-center text-blue-400 font-bold">
        <Male className="w-3rem h-3rem" /> Mascul
      </div>
    ) : (
      <div className="flex align-items-center text-green-400 font-bold">
        <Female className="w-3rem h-3rem" /> Femela
      </div>
    );
  };

  const activityFilterTemplate = (options: any) => {
    return (
      <React.Fragment>
        <Slider
          value={options.value}
          onChange={(e) => options.filterCallback(e.value)}
          range
          className="m-3"
        ></Slider>
        <div className="flex align-items-center justify-content-between px-2">
          <span>{options.value ? options.value[0] : 0}</span>
          <span>{options.value ? options.value[1] : 100}</span>
        </div>
      </React.Fragment>
    );
  };

  const representativeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <img
          alt={rowData.name}
          src={rowData.imgUrl || defaultImg}
          style={{ verticalAlign: "middle" }}
          className="w-2rem mr-2"
        />

        <span className="image-text">{rowData.name}</span>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <span className={`customer-badge status-${rowData.status}`}>
        {rowData.status}
      </span>
    );
  };

  const actionsBodyTemplate = (rowData: any) => {
    return (
      <div className="flex ">
        <Button
          label="Pedigree"
          icon="pi pi-sitemap"
          iconPos="left"
          onClick={() =>
            navigate(`${ROUTE_CONSTANTS.PET_PEDIGREE}/${rowData.pigeonId}`)
          }
        />
        <Button
          label="Detalii"
          icon="pi pi-info-circle"
          iconPos="left"
          variant="outlined"
          onClick={() =>
            navigate(`${ROUTE_CONSTANTS.PET_DETAIL}/${rowData.pigeonId}`)
          }
        />
      </div>
    );
  };
  const header = renderHeader();

  const handleAddPet = () => {
    // navigate(ROUTE_CONSTANTS.ADD_EDIT_PETS);
    setIsAddingPet(true);
  };

  const renderActions = () => {
    return <Button label="Adauga porumbel" onClick={handleAddPet} />;
  };

  return (
    <PageContainer name="Lista porumbei">
      <Card insideClassName="bg-white p-3">
        <Header rightChildren={renderActions()} />
        <DataTable
          value={pets}
          paginator
          className="p-datatable-customers"
          showGridlines
          rows={10}
          dataKey="id"
          filters={filters}
          filterDisplay="menu"
          loading={loadingStore.isLoading.component}
          responsiveLayout="scroll"
          globalFilterFields={["name"]}
          header={header}
          emptyMessage="Nu sunt date de afisat."
        >
          <Column
            field="name"
            header="Nume"
            filter
            body={representativeBodyTemplate}
            filterPlaceholder="Filtreaza dupa nume"
            style={{ minWidth: "12rem" }}
          />
          <Column
            field="ringNumber"
            header="Serie Inel"
            filterField="ringNumber"
            showFilterMatchModes={false}
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "14rem" }}
            filter
          />
          <Column
            field="year"
            header="An"
            filterField="year"
            style={{ minWidth: "10rem" }}
            filter
          />
          <Column
            header="Gen"
            filterField="balance"
            dataType="numeric"
            style={{ minWidth: "10rem" }}
            body={genderBodyTemplate}
            filter
            // filterElement={balanceFilterTemplate}
          />
          <Column
            field="petColor"
            header="Culoare"
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "12rem" }}
            filter
            // filterElement={statusFilterTemplate}
          />
          <Column
            field="family"
            header="Familie"
            dataType="boolean"
            bodyClassName="text-center"
            style={{ minWidth: "8rem" }}
            // body={verifiedBodyTemplate}
            filter
            // filterElement={verifiedFilterTemplate}
          />
          <Column
            field="status"
            header="Status"
            showFilterMatchModes={false}
            style={{ minWidth: "12rem" }}
            body={statusBodyTemplate}
            filter
            filterElement={activityFilterTemplate}
          />

          <Column
            field="actions"
            header="Actiuni"
            showFilterMatchModes={false}
            style={{ minWidth: "12rem" }}
            body={actionsBodyTemplate}
          />
        </DataTable>
        <AddEditPet visible={isAddingPet} setVisible={setIsAddingPet} />
      </Card>
    </PageContainer>
  );
});
