import { CountDownTimer } from "components/count-down/count-down";
import { PageContainer } from "components/page-container/page-container";
import dayjs from "dayjs";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { Divider } from "primereact/divider";
import { useParams } from "react-router-dom";
import { useStore } from "store";

export const AuctionPage = observer(() => {
  const { auctionStore } = useStore();

  const params = useParams();
  const auctionId = params.id;

  const auction = computed(() =>
    auctionStore.getAuctionById(auctionId || "")
  ).get();

  return (
    <PageContainer
      name={`Detalii licitatie - ${auction.user.firstName} ${auction.user.lastName}`}
      hasBackButton
    >
      <div className="w-full flex justify-content-between h-4rem align-items-center">
        <p className="m-0 h-full">
          <span className="block mb-1">
            <span className="font-bold text-gray-400">Start: </span>
            <span className="font-bold text-blue-700">
              {dayjs(auction.startDate).format("DD/MM/YYYY HH:mm")}
            </span>{" "}
          </span>
          <span className="block">
            <span className="font-bold text-gray-400">Finish: </span>
            <span className="font-bold text-blue-700">
              {dayjs(auction.endDate).format("DD/MM/YYYY HH:mm")}
            </span>
          </span>
        </p>
        <div className="w-10rem h-full border-round-md bg-blue-100 font-bold text-2xl flex align-items-center justify-content-center flex-column">
          <p className="m-0 text-sm">Timp pana la final:</p>
          <CountDownTimer milliseconds={20000000} />
        </div>
      </div>
      <Divider />
    </PageContainer>
  );
});
