import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import { fieldType } from "../add-edit-pet";

interface IProps {
  options: { label: any; value: string }[];
  field: fieldType;
  control: any;
  rules?: any;
  getErrorMessage: (name: fieldType) => JSX.Element | undefined;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

export const FormSelect = ({
  options,
  field,
  control,
  rules,
  placeholder,
  label,
  getErrorMessage,
  disabled = false,
}: IProps) => {
  return (
    <div className="m-2 pb-3">
      <p className="m-0 mb-2 display-block">{label}</p>
      <span>
        <Controller
          name={field}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <Dropdown
                {...field}
                options={options}
                optionLabel="label"
                optionValue="value"
                editable
                placeholder={placeholder || "Selectati o optiune"}
                className={classNames("p-inputtext-sm w-15rem shadow-2", {
                  "p-invalid": fieldState.error,
                })}
                disabled={disabled}
              />
            );
          }}
        />
      </span>
      <div className="ml-3">{getErrorMessage(field)}</div>
    </div>
  );
};
