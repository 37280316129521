import { observer } from "mobx-react-lite";
import { Sidebar } from "primereact/sidebar";
import { Calendar } from "primereact/calendar";

interface IProps {
  visible: boolean;
  onHide: () => void;
}

export const AddEditAuction = observer(({ visible, onHide }: IProps) => {
  return (
    <Sidebar visible={visible} onHide={onHide} position="right">
      <h3>Adauga licitatie</h3>
      <Calendar touchUI showTime hourFormat="24" />
    </Sidebar>
  );
});
