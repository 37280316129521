import { Dropdown } from "primereact/dropdown";
import { countries } from "constants/countries";
import { fieldType } from "../add-edit-pet";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";

interface IProps {
  field: fieldType;
  control: any;
  rules: any;
  getErrorMessage: (name: fieldType) => JSX.Element | undefined;
  placeholder?: string;
  label?: string;
}

export const CountrySelect = ({
  field,
  control,
  rules,
  getErrorMessage,
  placeholder,
  label,
}: IProps) => {
  const selectedCountryTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
            alt=""
            className="mr-2"
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
          alt=""
          className="mr-2"
        />
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <div className="m-2 pb-3">
      <p className="m-0 mb-2 display-block">{label}</p>
      <span>
        <Controller
          name={field}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => (
            <Dropdown
              {...field}
              filter
              options={countries}
              optionLabel="name"
              placeholder={placeholder || "Selectati o optiune"}
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              className={classNames("p-inputtext-sm w-15rem shadow-2", {
                "p-invalid": fieldState.error,
              })}
            />
          )}
        />
      </span>
      <div className="ml-3">{getErrorMessage(field)}</div>
    </div>
  );
};
