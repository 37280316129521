import { Button as PrimeButton } from "primereact/button";
import { classNames } from "primereact/utils";
import "./button.scss";

interface Props {
  label: string;
  onClick: () => void;
  icon?: string;
  iconPos?: "left" | "right";
  type?: "button" | "submit" | "reset";
  variant?: "text" | "outlined" | "contained" | "danger";
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Button = ({
  label,
  icon = "",
  onClick,
  iconPos = "left",
  type = "button",
  variant = "contained",
  loading = false,
  disabled = false,
  children,
}: Props) => {
  return (
    <PrimeButton
      icon={icon}
      className={classNames("button shadow-2", {
        [`button__outlined`]: variant === "outlined",
        [`button__danger`]: variant === "danger",
      })}
      label={label}
      onClick={onClick}
      iconPos={iconPos}
      type={type}
      loading={loading}
      disabled={disabled}
    >
      {children}
    </PrimeButton>
  );
};
