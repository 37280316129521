import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

interface Props {
  label: string;
  value: string;
}

export const ProfileSection = ({ label = "", value = "" }: Props) => {
  return (
    <div className="w-full">
      <div className="flex justify-content-between w-full">
        <div className="text-blue-800">
          <h3>{label}</h3>
          {value && <span>{value}</span>}
          {!value && <span className="text-gray-500">Nimic de afisat</span>}
        </div>
        <Button className="mt-2 h-2rem p-button-outlined">
          <i className="pi pi-pencil" />
          <span className="ml-2">edit</span>
        </Button>
      </div>
      <Divider />
    </div>
  );
};
