import { FIREBASE_KEYS } from "constants/appConstants";
import { getFirestore, getDocs, collection, getDoc } from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import { IAuction } from "model/auction-model";

export class AuctionStore {
  auctions: any[] = [];
  rootStore: any;

  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getAuctionById(id: string) {
    return this.auctions.find((auction) => auction.auctionId === id);
  }

  async getAuctions() {
    this.rootStore.loadingStore.setIsLoading("component", true);
    this.auctions = [];
    const db = getFirestore();
    const queryPets = await getDocs(collection(db, FIREBASE_KEYS.AUCTIONS));
    queryPets.docs.forEach(async (doc) => {
      const auction: IAuction = {
        ...doc.data(),
        auctionId: doc.id,
      };

      await getDoc(auction.userRef).then((snap) => {
        auction.user = snap.data();
      });
      await getDoc(auction.petRef).then((snap) => {
        auction.pet = snap.data();
      });

      this.auctions.push(auction);
    });

    this.rootStore.loadingStore.setIsLoading("component", false);
  }
}
