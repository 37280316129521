import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./sidebar.module.scss";

export const NavElement = ({
  label = "",
  to = "",
  icon = "",
  setIsSidebarOpen,
}: {
  label: string;
  to: string;
  icon: string;
  setIsSidebarOpen: (close: boolean) => void;
}) => {
  return (
    <NavLink
      className={({ isActive }) =>
        `text-base no-underline p-2 ml-3 mr-3 my-1 text-blue-800 border-round-md ${
          styles.sidebarNavItem
        } ${isActive ? `font-bold ${styles.sidebarNavItemActive}` : ""}`
      }
      to={to}
      onClick={() => setIsSidebarOpen(false)}
    >
      <i className={`pi ${icon} mr-3`} />
      {label}
    </NavLink>
  );
};
