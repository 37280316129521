import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import styles from "../login/login.module.scss";
import icon from "../../assets/png/icon.png";
import { observer } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { useStore } from "store";
import { Link, useNavigate } from "react-router-dom";

const defaultValues = {
  email: "",
};

export const ResetPassword = observer(() => {
  const { authStore, loadingStore } = useStore();
  const { isLoading } = loadingStore;
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const onSubmit = (formData: any) => {
    authStore.sendResetPasswordEmail(formData, navigate);
  };

  const getFormErrorMessage = (name: "email") => {
    return (
      errors[name] && <small className="p-error">{errors[name]?.message}</small>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-1 bg-cyan-50 m-auto w-full h-full max-h-full overflow-auto p-5 ${styles.container}`}
    >
      <div className="card bg-white w-20rem shadow-4 border-round-lg flex justify-content-center align-items-center m-auto flex-column">
        <div className="w-5rem mt-3">
          <img
            src={icon}
            alt="an pigeon with an dog and a cat with a gradient blue to red background"
            className="w-auto h-full max-h-full max-w-full"
          />
        </div>
        <h2 className="text-blue-800">Reseteaza parola!</h2>
        <div className=" m-2">
          <span className="p-input-icon-left ">
            <i className="pi pi-at" />
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email-ul este obligatoriu!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "E-mail invalid!",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  placeholder="Email"
                  className={classNames("p-inputtext-sm w-15rem shadow-2", {
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
          </span>
          <div className="ml-3">{getFormErrorMessage("email")}</div>
        </div>
        <Button
          // onClick={sendCode}
          type="submit"
          className="m-2 p-button-sm w-15rem justify-content-center h-2rem mb-5"
          loading={isLoading.button}
          label="Trmite e-mail"
        />

        <div className="flex w-16rem justify-content-center b-2">
          <span>Ai datele de login?</span>
          <Link
            className="no-underline text-blue-600 font-bold mb-5"
            to="/login"
            replace
          >
            mergi la login!
          </Link>
        </div>
      </div>
    </form>
  );
});
