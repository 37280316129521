export const colors = [
  "#FFEBEE",
  "#FFCDD2",
  "#EF9A9A",
  "#E57373",
  "#FF8A80",
  "#FF5252",
  "#FCE4EC",
  "#F8BBD0",
  "#F48FB1",
  "#F06292",
  "#FF80AB",
  "#FF4081",
  "#F3E5F5",
  "#E1BEE7",
  "#CE93D8",
  "#BA68C8",
  "#EA80FC",
  "#E040FB",
  "#EDE7F6",
  "#D1C4E9",
  "#B39DDB",
  "#9575CD",
  "#B388FF",
  "#7C4DFF",
  "#E8EAF6",
  "#C5CAE9",
  "#9FA8DA",
  "#7986CB",
  "#8C9EFF",
  "#536DFE",
  "#E3F2FD",
  "#BBDEFB",
  "#90CAF9",
  "#64B5F6",
  "#82B1FF",
  "#448AFF",
  "#E1F5FE",
  "#B3E5FC",
  "#81D4FA",
  "#4FC3F7",
  "#80D8FF",
  "#40C4FF",
  "#E0F7FA",
  "#B2EBF2",
  "#80DEEA",
  "#4DD0E1",
  "#84FFFF",
  "#18FFFF",
  "#E0F2F1",
  "#B2DFDB",
  "#80CBC4",
  "#4DB6AC",
  "#A7FFEB",
  "#64FFDA",
  "#E8F5E9",
  "#C8E6C9",
  "#A5D6A7",
  "#81C784",
  "#B9F6CA",
  "#69F0AE",
  "#F1F8E9",
  "#DCEDC8",
  "#C5E1A5",
  "#AED581",
  "#CCFF90",
  "#B2FF59",
  "#F9FBE7",
  "#F0F4C3",
  "#E6EE9C",
  "#DCE775",
  "#F4FF81",
  "#EEFF41",
  "#FFFDE7",
  "#FFF9C4",
  "#FFF59D",
  "#FFF176",
  "#FFFF8D",
  "#FFFF00",
  "#FFF8E1",
  "#FFECB3",
  "#FFE082",
  "#FFD54F",
  "#FFE57F",
  "#FFD740",
  "#FFF3E0",
  "#FFE0B2",
  "#FFCC80",
  "#FFB74D",
  "#FFD180",
  "#FFAB40",
  "#FBE9E7",
  "#FFCCBC",
  "#FFAB91",
  "#FF8A65",
  "#FF9E80",
  "#FF6E40",
  "#EFEBE9",
  "#D7CCC8",
  "#BCAAA4",
  "#A1887F",
  "#ECEFF1",
  "#CFD8DC",
  "#B0BEC5",
  "#90A4AE",
];
