import React, { useEffect, useRef } from "react";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import { AppRouting } from "./AppRouting";
import { initializeApp } from "firebase/app";
import { Toast } from "primereact/toast";
import { useStore } from "store";
import { useNavigate } from "react-router-dom";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

initializeApp(firebaseConfig);

function App() {
  const ref = useRef(null);
  const { loadingStore, authStore } = useStore();
  const { toastRef } = loadingStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (!toastRef && ref) {
      loadingStore.setToastRef(ref);
    }
  }, [ref, toastRef]); //eslint-disable-line

  useEffect(() => {
    authStore.checkUserStatus(navigate);
  }, []); //eslint-disable-line

  return (
    <>
      <AppRouting />
      <Toast ref={ref} />
    </>
  );
}

export default App;
