import { ConfirmEmail } from "auth/confirm-email/confirm-email";
import { ProtectedRoute } from "auth/protected-route";
import { ResetPassword } from "auth/reset-password/reset-password";
import { VerifyEmail } from "auth/verify-email/verify-email";
import { ROUTE_CONSTANTS } from "constants/routeConstants";
import { AuctionList } from "pages/auction-list/auction-list";
import { AuctionPage } from "pages/auction-page/auction-page";
import { Contest } from "pages/contests/contests";
import { PetDetail } from "pages/pet-detail/pet-detail";
import { PetList } from "pages/pet-list/pet-list";
import { PetPedigree } from "pages/pet-pedigree/pet-pedigree";
import { PetSales } from "pages/pet-sales/pet-sales";
import { Profile } from "pages/profile/profile";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "./auth/login/login";
import { Signup } from "./auth/signup/signup";
import { Dashboard } from "./pages/dashboard/dashboard";
import { AppLayout } from "./pages/home/home-page";

export const AppRouting = () => {
  return (
    <Routes>
      <Route path={ROUTE_CONSTANTS.LOGIN} element={<Login />} />
      <Route path={ROUTE_CONSTANTS.SIGNUP} element={<Signup />} />
      <Route path={ROUTE_CONSTANTS.CONFIRMATION} element={<ConfirmEmail />} />
      <Route path={ROUTE_CONSTANTS.RESET} element={<ResetPassword />} />
      <Route path={ROUTE_CONSTANTS.EMAIL_VERIFY} element={<VerifyEmail />} />
      <Route path="/*" element={<AppLayout />}>
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.PROFILE}
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_CONSTANTS.PET_LIST}
          element={
            <ProtectedRoute>
              <PetList />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path={`${ROUTE_CONSTANTS.PET_LIST}/${ROUTE_CONSTANTS.ADD_EDIT_PETS}`}
          element={
            <ProtectedRoute>
              <AddEditPet />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path={`${ROUTE_CONSTANTS.PET_LIST}/${ROUTE_CONSTANTS.PET_PEDIGREE_ID}`}
          element={
            <ProtectedRoute>
              <PetPedigree />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ROUTE_CONSTANTS.PET_LIST}/${ROUTE_CONSTANTS.PET_DETAIL_ID}`}
          element={
            <ProtectedRoute>
              <PetDetail />
            </ProtectedRoute>
          }
        />
        <Route path={ROUTE_CONSTANTS.AUCTIONS} element={<AuctionList />} />
        <Route path={ROUTE_CONSTANTS.AUCTIONS_ID} element={<AuctionPage />} />
        <Route path={ROUTE_CONSTANTS.PET_SALES} element={<PetSales />} />
        <Route path={ROUTE_CONSTANTS.CONTESTS} element={<Contest />} />
        <Route
          path={ROUTE_CONSTANTS.DISTANCE_CALCULATOR}
          element={<Contest />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_CONSTANTS.AUCTIONS} replace />}
        />
      </Route>
    </Routes>
  );
};
