export const ROUTE_CONSTANTS = {
  DASHBOARD: "dashboard",
  AUCTIONS: "auction-list",
  AUCTIONS_ID: "auction-list/:id",
  PET_SALES: "pet-sales",
  LOGIN: "/login",
  PROFILE: "profile",
  SIGNUP: "/signup",
  EMAIL_VERIFY: "/email-verify",
  CONFIRMATION: "/confirmation",
  RESET: "/reset",
  PET_LIST: "pet-list",
  PET_DETAIL: "pet-detail",
  PET_DETAIL_ID: "pet-detail/:id",
  PET_PEDIGREE: "pet-pedigree",
  PET_PEDIGREE_ID: "pet-pedigree/:id",
  CONTESTS: "contests",
  DISTANCE_CALCULATOR: "distance-calculator",
};
