import { Divider } from "primereact/divider";
import { NoContests } from "../no-contests/no-contests";

interface IProps {
  contestList: any[];
}

export const Contests = ({ contestList }: IProps) => {
  return (
    <div className="w-full">
      <h3 className="text-blue-700">Concursuri:</h3>
      {contestList.length === 0 && <NoContests />}
      <Divider />
    </div>
  );
};
